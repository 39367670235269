import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getWishListCourcesByUser, removeCourseFromWishList, } from '../../../Constants/Api/functions/function';
import { WhatsAppOutlined, HeartFilled, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, List, Spin } from 'antd';

import './styles.scss'
import { Link } from 'react-router-dom';



const WishList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const [wishlistCourses, setWishlistCourses] = useState();

    useEffect(() => {
        if (userProfile) {
            getCourseData(userProfile.id);
        }
    }, [])

    const getCourseData = async (userId) => {
        setIsLoading(true);
        try {
            const response = await getWishListCourcesByUser(userId);
            setWishlistCourses(response);
        } catch (error) {
            console.log(error);
        }

        setIsLoading(false);
    }

    const handleRemoveFromWishList = async (courseId) => {
        setIsLoading(true);
        try {
            const response = await removeCourseFromWishList(userProfile.id, courseId);
            if (response) {
                getCourseData(userProfile.id);
            }
        } catch (error) {
            console.log(error);
        }

        setIsLoading(false);
    }

    return (
        <div className='wishlist-page'>
            <div className='notes-header'>
                <h1>Wish List</h1>
            </div>

            <Spin spinning={isLoading}>
                <div className='wishlist-list'>
                    <List
                        grid={{
                            gutter: 30,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 2,
                            xl: 3,
                            xxl: 4,
                        }}
                        dataSource={wishlistCourses}
                        renderItem={(item) => (
                            <List.Item>
                                <div className='course-card'>
                                    <div className='card-image-container'>
                                        <img
                                            src={
                                                item?.coverImage
                                                && item?.coverImage
                                            } alt=""
                                        />
                                        <div className='cource-card-header-buttons'>
                                            <div
                                                className='duration'
                                            >
                                                <Button
                                                    type='link'
                                                    onClick={() => handleRemoveFromWishList(item.id)}
                                                >
                                                    <HeartFilled />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='card-description'>
                                            <div className='price'>
                                                {
                                                    item?.price === 0
                                                        ?
                                                        <p className='free-text'>
                                                            Free
                                                        </p>
                                                        :
                                                        <p>
                                                            {item?.price} LKR
                                                        </p>
                                                }
                                            </div>
                                        </div>

                                        <h3 className='title'>
                                            {item?.name}
                                        </h3>

                                        <>
                                            {
                                                item?.whatsappGroupLink &&
                                                <a href={item?.whatsappGroupLink} target='_blank' rel="noreferrer">
                                                    <Button type='primary' style={{
                                                        backgroundColor: '#25d366',
                                                    }}>
                                                        <WhatsAppOutlined />
                                                        Join Whatsapp Group
                                                    </Button>
                                                </a>
                                            }
                                        </>
                                    </div>

                                    <div className='card-footer'>
                                        <div className='button-continer'>
                                            <>
                                                <Link to={`/dashboard/cources/${item.id}/buy`}>
                                                    <Button
                                                        type='primary'
                                                        icon={<ShoppingCartOutlined />}
                                                    >
                                                        Buy This Course
                                                    </Button>
                                                </Link>
                                                {
                                                    item?.isPublished &&
                                                    <Link to={`/dashboard/cources/${item.id}`}>
                                                        <Button type='link'>
                                                            View
                                                        </Button>
                                                    </Link>
                                                }
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default WishList