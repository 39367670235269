import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { deleteAssestment, getAssementByIdAndRole } from '../../../../Constants/Api/functions/function';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';

import { Button, Divider, Popconfirm, Spin, notification } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import './style.scss'
import EditAssessment from './EditAssessment';
import Submission from './Submission';
import SubmitAssessment from './SubmitAssessment';

const Assessment = () => {
    const [loading, setLoading] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const assessmentId = window.location.href.split('/').pop();
    const [api, contextHolder] = notification.useNotification();
    const allAssessments = useSelector(state => state.allAssessments.allAssessments);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const [assessmentData, setAssessmentData] = useState();

    useEffect(() => {
        if (allAssessments?.length) {
            const assessment = allAssessments?.find(assessment => assessment.id === assessmentId);
            setAssessmentData(assessment);
        } else {
            handleGetAssessment();
        }
    }, [allAssessments, assessmentId])

    const handleGetAssessment = async () => {
        setLoading(true);
        try {
            const response = await getAssementByIdAndRole(assessmentId, userProfile?.role, userProfile.id);
            setAssessmentData(response);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleDeleteAssessment = async () => {
        try {
            const response = await deleteAssestment(assessmentId);
            if (response && response.status === 200) {
                openNotification('success', 'Assessment deleted successfully', '')

                setTimeout(() => {
                    window.location.href = '/dashboard/assessments';
                }, 1000);
            } else {
                openNotification('error', 'Failed to delete assessment', '')
            }
        } catch (error) {
            console.log(error);
            openNotification('error', 'Failed to delete assessment', '')
        }
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div className='assessment'>
            {contextHolder}
            {loading
                ?
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                        width: '100%'
                    }}
                >
                    <Spin
                        tip="Loading..."
                        size='large'
                    />
                </div>
                :
                <>
                    <div className='left'>
                        <div className='hedaer'>
                            <h1>
                                {assessmentData?.name}
                            </h1>

                            {userProfile?.role === 'superadmin' && <div className='actions'>
                                <Button
                                    type='link'
                                    className='btn'
                                    onClick={() => setOpenEdit(true)}
                                >
                                    <EditOutlined />
                                </Button>

                                <Popconfirm
                                    title='Are you sure to delete this assessment?'
                                    onConfirm={handleDeleteAssessment}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <Button
                                        type='link'
                                        className='btn'
                                        danger
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>
                            </div>}
                        </div>

                        <div className='body'>
                            <h2>
                                Due date: <span>{assessmentData?.endDate}</span>
                            </h2>
                            <Divider />
                            <h2>
                                Description:
                            </h2>
                            <ReactQuill
                                className='quill'
                                readOnly
                                theme="bubble"
                                value={assessmentData?.description}
                            />
                            <Divider />
                            <h2>
                                Resource materials: <a
                                    href={assessmentData?.assessmentUrl}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {assessmentData?.assessmentUrl}
                                </a>
                            </h2>
                        </div>
                    </div>

                    <div className='right'>
                        {
                            userProfile?.role === 'student'
                                ?
                                <SubmitAssessment
                                    assessmentData={assessmentData}
                                    handleGetAssessment={handleGetAssessment}
                                    userProfile={userProfile}
                                    contextHolder={contextHolder}
                                    api={api}
                                />
                                :
                                <Submission
                                    assessmentId={assessmentId}
                                />
                        }
                    </div>
                </>}

            <EditAssessment
                assessmentData={assessmentData}
                isOpen={openEdit}
                setOpen={setOpenEdit}
                handleGetAssessment={handleGetAssessment}
            />
        </div>
    )
}

export default Assessment