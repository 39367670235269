import { createSlice } from "@reduxjs/toolkit";

const allAssessmentsReducer = createSlice({
    name: "allAssessments",
    initialState: {
        allAssessments: null,
        isLoading: false,
        error: null
    },
    reducers: {
        setAllAssessments: (state, action) => {
            state.allAssessments = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setAllAssessments, setIsLoading, setError } = allAssessmentsReducer.actions

export default allAssessmentsReducer.reducer;