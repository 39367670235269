import axios from 'axios';

const { BASE_URL } = require('../azure/azure');

const getUserById = async (userId) => {
    try {
        const url = `${BASE_URL}/getUserById?id=${userId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getUserById', error);
        return false;
    }
}

const signInUser = async (credentials) => {
    try {
        const url = `${BASE_URL}/signinUser`;
        const response = await axios.post(url, credentials);
        return response;
    } catch (error) {
        console.error('signInUser', error);
        return error.response;
    }
}

const checkForPasswordReset = async (email, Token) => {
    try {
        const url = `${BASE_URL}/checkForPasswordReset?email=${email}&token=${Token}`;
        const response = await axios.get(url);
        return response;
    } catch (error) {
        console.error('checkForPasswordReset', error);
        return error.response;
    }
}

const resetPassword = async (resetData) => {
    try {
        const url = `${BASE_URL}/resetPassword`;
        const response = await axios.post(url, resetData);
        return response;
    } catch (error) {
        console.error('resetPassword', error);
        return error.response;
    }
}

const requestPasswordReset = async (email) => {
    try {
        const url = `${BASE_URL}/requestPasswordReset?email=${email}`;
        const response = await axios.get(url);
        return response;
    } catch (error) {
        console.error('requestPasswordReset', error);
        return error.response;
    }
}

const addUser = async (user) => {
    try {
        const url = `${BASE_URL}/addUser`;
        const response = await axios.post(url, user);
        return response;
    } catch (error) {
        console.error('addUser', error);
        return false;
    }
}

const checkIsEmailExist = async (email) => {
    try {
        const url = `${BASE_URL}/checkIsEmailExists?email=${email}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('checkIsEmailExist', error);
        return false;
    }
}

const getAllUsers = async () => {
    try {
        const url = `${BASE_URL}/getAllUsers`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAllUsers', error);
        return false;
    }
}

const createUser = async (user) => {
    try {
        const url = `${BASE_URL}/createUser`;
        const response = await axios.post(url, user);
        return response;
    } catch (error) {
        console.error('createUser', error);
        return false;
    }
}

const deleteUser = async (userId) => {
    try {
        const url = `${BASE_URL}/deleteUser?userId=${userId}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteUser', error);
        return false;
    }
}

const editUser = async (user) => {
    try {
        const url = `${BASE_URL}/editUser`;
        const response = await axios.put(url, user);
        return response;
    } catch (error) {
        console.error('editUser', error);
        return false;
    }
}

const getProgressByUserId = async (userId) => {
    try {
        const url = `${BASE_URL}/getProgressByUserId?userId=${userId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getProgressByUserId', error);
        return false;
    }
}

const addCourse = async (course) => {
    try {
        const url = `${BASE_URL}/addCourse`;
        const response = await axios.post(url, course);
        return response;
    } catch (error) {
        console.error('addCourse', error);
        return false;
    }
}

const getAllCoursesByRole = async (role) => {
    try {
        const url = `${BASE_URL}/getAllCoursesByRole?role=${role}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAllCoursesByRole', error);
        return false;
    }
}

const getCourseContentByRoleAndCourseId = async (courseId, role, userId) => {
    try {
        const url = `${BASE_URL}/getCourseContentByRoleAndCourseId?role=${role}&courseId=${courseId}&userId=${userId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getCourseContentByRoleAndCourseId', error);
        return false;
    }
}

const enrollToCourseContent = async (courseId, userId, contentId) => {
    try {
        const url = `${BASE_URL}/enrollToCourseContent?courseId=${courseId}&userId=${userId}&courseContentId=${contentId}`;
        const response = await axios.post(url);
        return response;
    } catch (error) {
        console.error('enrollToCourseContent', error);
        return false;
    }
}

const addCourseContent = async (content) => {
    try {
        const url = `${BASE_URL}/addCourseContent`;
        const response = await axios.post(url, content);
        return response;
    } catch (error) {
        console.error('addCourseContent', error);
        return false;
    }
}

const editCourseContent = async (content) => {
    try {
        const url = `${BASE_URL}/editCourseContent`;
        const response = await axios.put(url, content);
        return response;
    } catch (error) {
        console.error('editCourseContent', error);
        return false;
    }
}

const deleteCourseContent = async (contentId) => {
    try {
        const url = `${BASE_URL}/deleteCourseContent?contentId=${contentId}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteCourseContent', error);
        return false;
    }
}

const editCourse = async (course) => {
    try {
        const url = `${BASE_URL}/editCourse`;
        const response = await axios.put(url, course);
        return response;
    } catch (error) {
        console.error('editCourse', error);
        return false;
    }
}

const deleteCourse = async (courseId) => {
    try {
        const url = `${BASE_URL}/deleteCourse?courseId=${courseId}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteCourse', error);
        return false;
    }
}

const getCourseById = async (courseId) => {
    try {
        const url = `${BASE_URL}/getCourseById?courseId=${courseId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getCourseById', error);
        return false;
    }
}

const getAllUsersOfACourse = async (courseId) => {
    try {
        const url = `${BASE_URL}/getAllUsersOfACourse?courseId=${courseId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getCourseById', error);
        return false;
    }
}

const buyACourse = async (data) => {
    try {
        const url = `${BASE_URL}/buyACourse`;
        const response = await axios.post(url, data);
        return response;
    } catch (error) {
        console.error('addCourseContent', error);
        return false;
    }
}

const removeAUserFromACourse = async (userId, courseId) => {
    try {
        const url = `${BASE_URL}/removeAUserFromACourse?userId=${userId}&courseId=${courseId}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('removeAUserFromACourse', error);
        return false;
    }
}

const getUsersCourses = async (userId) => {
    try {
        const url = `${BASE_URL}/getUsersCourses?userId=${userId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getUsersCourses', error);
        return false;
    }
}

const getPaymentsByUserId = async (userId) => {
    try {
        const url = `${BASE_URL}/getPaymentsByUserId?userId=${userId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getPaymentsByUserId', error);
        return false;
    }
}

const getAllPayments = async () => {
    try {
        const url = `${BASE_URL}/getAllPayments`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAllPayments', error);
        return false;
    }
}

const addAssestment = async (values) => {
    try {
        const url = `${BASE_URL}/addAssestment`;
        const response = await axios.post(url, values);
        return response;
    } catch (error) {
        console.error('addAssestment', error);
        return false;
    }
}

const getAssestmentsByRole = async (role, id) => {
    try {
        const url = `${BASE_URL}/getAssestmentsByRole?role=${role}&id=${id}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAssestmentsByRole', error);
        return false;
    }
}

const getCouseAssestmentsByRole = async (role, id, courseId) => {
    try {
        const url = `${BASE_URL}/getCouseAssestmentsByRole?role=${role}&id=${id}&courseId=${courseId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getCouseAssestmentsByRole', error);
        return false;
    }
}

const editAssestment = async (values) => {
    try {
        const url = `${BASE_URL}/editAssestment`;
        const response = await axios.put(url, values);
        return response;
    } catch (error) {
        console.error('editAssestment', error);
        return false;
    }
}

const reviewAssestmentSubmission = async (values) => {
    try {
        const url = `${BASE_URL}/reviewAssestmentSubmission`;
        const response = await axios.put(url, values);
        return response;
    } catch (error) {
        console.error('reviewAssestmentSubmission', error);
        return false;
    }
}

const getSubmissionsByAssestment = async (id) => {
    try {
        const url = `${BASE_URL}/getSubmissionsByAssestment?id=${id}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getSubmissionsByAssestment', error);
        return false;
    }
}

const deleteAssessmentSubmission = async (id) => {
    try {
        const url = `${BASE_URL}/deleteAssessmentSubmission?id=${id}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteAssessmentSubmission', error);
        return false;
    }
}

const attemptToAssestment = async (values) => {
    try {
        const url = `${BASE_URL}/attemptToAssestment`;
        const response = await axios.post(url, values);
        return response;
    } catch (error) {
        console.error('attemptToAssestment', error);
        return false;
    }
}

const editAttempt = async (values) => {
    try {
        const url = `${BASE_URL}/editAttempt`;
        const response = await axios.put(url, values);
        return response;
    } catch (error) {
        console.error('editAttempt', error);
        return false;
    }
}

const getAssementByIdAndRole = async (id, role, urerId) => {
    try {
        const url = `${BASE_URL}/getAssementByIdAndRole?id=${id}&role=${role}&userId=${urerId}`;
        const response = await axios.get(url);
        return response.data[0]
    } catch (error) {
        console.error('getAssementByIdAndRole', error);
        return false;
    }
}

const deleteAssestment = async (id) => {
    try {
        const url = `${BASE_URL}/deleteAssestment?id=${id}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteAssestment', error);
        return false;
    }
}

const addSession = async (session) => {
    try {
        const url = `${BASE_URL}/addSession`;
        const response = await axios.post(url, session);
        return response;
    } catch (error) {
        console.error('addSession', error);
        return false;
    }
}

const getSessions = async (role, userId) => {
    try {
        const url = `${BASE_URL}/getSessions?role=${role}&userId=${userId}`;
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.error('getSessions', error);
        return false;
    }
}

const deleteSession = async (id) => {
    try {
        const url = `${BASE_URL}/deleteSession?id=${id}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteSession', error);
        return false;
    }
}

const editSession = async (session) => {
    try {
        const url = `${BASE_URL}/editSession`;
        const response = await axios.put(url, session);
        return response;
    } catch (error) {
        console.error('editSession', error);
        return false;
    }
}

const addNote = async (note) => {
    try {
        const url = `${BASE_URL}/addNote`;
        const response = await axios.post(url, note);
        return response;
    } catch (error) {
        console.error('addNote', error);
        return false;
    }
}


const deleteNote = async (id) => {
    try {
        const url = `${BASE_URL}/deleteNote?noteId=${id}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deleteNote', error);
        return false;
    }
}

const attendToSession = async (sessionId, userId, isRepeat) => {
    isRepeat = isRepeat ? true : false;
    try {
        const url = `${BASE_URL}/attendToSession?sessionId=${sessionId}&userId=${userId}&isRepeat=${isRepeat}`;
        const response = await axios.post(url);
        return response;
    } catch (error) {
        console.error('attendToSession', error);
        return false;
    }
}

const getSessionById = async (sessionId) => {
    try {
        const url = `${BASE_URL}/getSessionById?sessionId=${sessionId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getSessionById', error);
        return false;
    }
}

const getAttendanceBySession = async (sessionId, isRepeat, sessionDate) => {
    try {
        const url = `${BASE_URL}/getAttendanceBySession?sessionId=${sessionId}&isRepeat=${isRepeat}&sessionDate=${sessionDate}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAttendanceBySession', error);
        return false;
    }
}

const getHomeCourses = async () => {
    try {
        const url = `${BASE_URL}/getHomeCourses`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getHomeCourses', error);
        return false;
    }
}

const getDashboardHomeCources = async () => {
    try {
        const url = `${BASE_URL}/getDashboardHomeCources`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getDashboardHomeCources', error);
        return false;
    }
}

const getNotesByUser = async (userId, role) => {
    try {
        const url = `${BASE_URL}/getNotesByUser?userId=${userId}&role=${role}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getNotesByUser', error);
        return false;
    }
}


const deletePayment = async (paymentId) => {
    try {
        const url = `${BASE_URL}/deletePayment?paymentId=${paymentId}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        console.error('deletePayment', error);
        return false;
    }
}

const getWishListCourcesByUser = async (userId) => {
    try {
        const url = `${BASE_URL}/getWishListCourcesByUser?userId=${userId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getWishListCourcesByUser', error);
        return false;
    }
}

const removeCourseFromWishList = async (userId, courseId) => {
    try {
        const url = `${BASE_URL}/removeCourseFromWishList?userId=${userId}&courseId=${courseId}`;
        const response = await axios.put(url);
        return response;
    } catch (error) {
        console.error('removeCourseFromWishList', error);
        return false;
    }
}


const addCourseToWishList = async (userId, courseId) => {
    try {
        const url = `${BASE_URL}/addCourseToWishList?userId=${userId}&courseId=${courseId}`;
        const response = await axios.put(url);
        return response;
    } catch (error) {
        console.error('addCourseToWishList', error);
        return false;
    }
}

const getAllWishLists = async () => {
    try {
        const url = `${BASE_URL}/getAllWishLists`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('getAllWishLists', error);
        return false;
    }
}

export {
    getUserById,
    signInUser,
    checkForPasswordReset,
    resetPassword,
    requestPasswordReset,
    addUser,
    checkIsEmailExist,
    getAllUsers,
    createUser,
    deleteUser,
    editUser,
    getProgressByUserId,
    addCourse,
    getAllCoursesByRole,
    getCourseContentByRoleAndCourseId,
    enrollToCourseContent,
    addCourseContent,
    editCourseContent,
    deleteCourseContent,
    editCourse,
    deleteCourse,
    getCourseById,
    getAllUsersOfACourse,
    buyACourse,
    removeAUserFromACourse,
    getUsersCourses,
    getPaymentsByUserId,
    getAllPayments,
    addAssestment,
    getAssestmentsByRole,
    editAssestment,
    reviewAssestmentSubmission,
    getSubmissionsByAssestment,
    deleteAssessmentSubmission,
    attemptToAssestment,
    editAttempt,
    getAssementByIdAndRole,
    deleteAssestment,
    addSession,
    getSessions,
    deleteSession,
    editSession,
    addNote,
    deleteNote,
    attendToSession,
    getSessionById,
    getAttendanceBySession,
    getHomeCourses,
    getDashboardHomeCources,
    getNotesByUser,
    deletePayment,
    getWishListCourcesByUser,
    removeCourseFromWishList,
    addCourseToWishList,
    getAllWishLists,
    getCouseAssestmentsByRole,
}