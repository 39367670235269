import React, { useEffect, useRef, useState } from 'react';

import { Button, Col, Drawer, Form, Input, Row, Select, Space, Divider, notification, Spin, Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';

import { editUser } from '../../../../../../Constants/Api/functions/function';
import { useSelector } from 'react-redux';

// Azure Storage
import { BlobServiceClient } from '@azure/storage-blob';
import { BLOB_SAS_URL, USER_PROFILE_IMAGE_BLOB_BASE_URL } from '../../../../../../Constants/Api/azure/azure';

const { Option } = Select;

const EditOverview = ({ open, setOpen, user, getUser }) => {
    const formRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const userProfile = useSelector(state => state.userProfile.userProfile)
    const [fileList, setFileList] = useState([]);
    const [isImageChanged, setIsImageChanged] = useState(false)

    useEffect(() => {
        if (user && formRef.current) {
            formRef.current.setFieldsValue({
                name: user.name,
                address: user.address,
                contactNumber: user.contactNumber,
                role: user.role,
            })

            setFileList(
                user?.profileImage ? [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: user?.profileImage
                    },
                ]
                    :
                    []
            );
        }
    }, [user, formRef, open])

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);
        values.id = user.id

        setIsLoading(true)
        try {
            if (isImageChanged) {
                const uploadRes = await uploadFile(user.id)

                if (uploadRes) {
                    values.profileImage = `${USER_PROFILE_IMAGE_BLOB_BASE_URL}${user.id}`
                }

                const response = await editUser(values)
                if (response && response.status === 200) {
                    openNotificationWithIcon('success')
                    setOpen(false)
                    getUser()
                }
            } else {
                const response = await editUser(values)
                if (response && response.status === 200) {
                    openNotificationWithIcon('success')
                    setOpen(false)
                    getUser()
                }
            }
        } catch (error) {
            openNotificationWithIcon('error', error)
        } finally {
            setIsLoading(false)
        }
    };

    const uploadFile = async (blobName) => {
        try {
            const blobSasUrl = BLOB_SAS_URL;
            const container = 'user-images';

            const containerClient = new BlobServiceClient(blobSasUrl).getContainerClient(container);

            const res = await containerClient.uploadBlockBlob(
                `${blobName}`,
                fileList[0].originFileObj,
                fileList[0].size
            );
            return res
        } catch (error) {
            console.log("error", error)
        }
    }

    const onClose = () => {
        setOpen(false);
    };

    const openNotificationWithIcon = (type, description) => {
        api[type]({
            message: type === 'success' ? 'Success' : 'Something went wrong',
            description: type === 'success' ? 'User profile updated successfully. Please refresh the page to see the changes'
                : `Error: ${description}`,
        });
    };

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        setIsImageChanged(true)
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <>
            {contextHolder}
            <Drawer
                title="Edit User Overview"
                width={720}
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            form='edit-overview-form'
                            type="primary"
                            htmlType='submit'
                        >
                            Update
                        </Button>
                    </Space>
                }
            >
                <Spin
                    spinning={isLoading}
                    tip="Updating user profile..."
                >
                    <Form
                        id='edit-overview-form'
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Row
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Col>
                                <Form.Item
                                    name="userImage"
                                    label="User Profile Image"
                                >
                                    <ImgCrop rotationSlider>
                                        <Upload
                                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                            listType="picture-card"
                                            fileList={fileList}
                                            onChange={onChange}
                                            onPreview={onPreview}
                                            beforeUpload={file => {
                                                const isPNG = (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg');
                                                if (!isPNG) {
                                                    message.error(`${file.name} is not a png file`);

                                                    return isPNG || Upload.LIST_IGNORE;
                                                } else {
                                                    return false;
                                                }
                                            }}
                                        >
                                            {fileList.length < 1 && '+ Upload'}
                                        </Upload>
                                    </ImgCrop>

                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="User Name"
                                >
                                    <Input placeholder="Please enter user name" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="contactNumber"
                                    label="Contact Number"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || /^0[0-9]{9}$/.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The input is not a valid phone number (e.g., 0XX XXXX XXX)!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="0XX XXXX XXX" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            {userProfile?.role === 'superadmin' &&
                                <Col span={12}>
                                    <Form.Item
                                        name="role"
                                        label="User Role"
                                    >
                                        <Select
                                            placeholder="Please select user role"
                                        >
                                            <Option value="superadmin">Super Admin</Option>
                                            <Option value="student">Student</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }

                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    label="Address"
                                >
                                    <Input placeholder="Please enter user address" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider
                            style={{
                                marginTop: '0px',
                                marginBottom: '15px'
                            }}
                        />
                    </Form>
                </Spin>
            </Drawer >
        </>
    );
};
export default EditOverview;