import { configureStore, combineReducers } from '@reduxjs/toolkit'

import userProfileReducer from './App/Reducers/userProfileReducer'
import allUsersReducer from './App/Reducers/allUsersReducer'
import allCourcesReducer from './App/Reducers/allCourcesReducer'
import allAssessmentsReducer from './App/Reducers/allAssessmentsReducer'
import allSessonsReducer from './App/Reducers/allSessonsReducer'
import allNotesReducer from './App/Reducers/allNotesReducer'
import usersCourcesReducer from './App/Reducers/usersCourcesReducer'

const rootReducer = combineReducers({
    userProfile: userProfileReducer,
    allUsers: allUsersReducer,
    allCources: allCourcesReducer,
    allAssessments: allAssessmentsReducer,
    allSessons: allSessonsReducer,
    allNotes: allNotesReducer,
    usersCources: usersCourcesReducer
})

const store = configureStore({
    reducer: rootReducer,
})

export default store