import React, { useEffect, useRef, useState } from 'react'

import { Button, Divider, Form, Input } from 'antd'

import './style.scss'
import { attemptToAssestment, editAttempt, getUserById } from '../../../../../Constants/Api/functions/function'

const SubmitAssessment = ({
    assessmentData,
    handleGetAssessment,
    userProfile,
    contextHolder,
    api
}) => {
    const [attempts, setAttempts] = useState()
    const formRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState();

    useEffect(() => {
        if (assessmentData) {
            if (!assessmentData?.attempt?.id) {
                setAttempts(3)
            } else {
                setAttempts(3 - assessmentData?.attempt?.attemptNumber)
            }

            if (assessmentData?.attempt?.isReviewed) {
                renderName(assessmentData?.attempt?.reviewdBy).then(res => {
                    setName(res);
                })
            }
        }
    }, [assessmentData])

    const onFinish = async (values) => {
        values.userId = userProfile?.id;
        values.assessmentId = assessmentData?.id;

        setIsLoading(true);
        try {
            const response = await attemptToAssestment(values);
            if (response && response.status === 200) {
                openNotification('success', 'Assessment submitted successfully', '')
                formRef.current.resetFields();
                setTimeout(() => {
                    handleGetAssessment();
                }, 1000);
            } else {
                openNotification('error', 'Failed to submit assessment', '')
            }
        } catch (error) {
            console.log(error);
            openNotification('error', 'Failed to submit assessment', '')
        }
        setIsLoading(false);
    }
    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    const onFinishReSubmit = async (values) => {
        values.id = assessmentData?.attempt?.id;
        values.userId = userProfile?.id;
        values.assessmentId = assessmentData?.id;

        setIsLoading(true);

        try {
            const response = await editAttempt(values);
            if (response && response.status === 200) {
                openNotification('success', 'Assessment re-submitted successfully', '')
                formRef.current.resetFields();
                setTimeout(() => {
                    handleGetAssessment();
                }, 1000);
            } else {
                openNotification('error', 'Failed to re-submit assessment', '')
            }
        }
        catch (error) {
            console.log(error);
            openNotification('error', 'Failed to re-submit assessment', '')
        }

        setIsLoading(false);
    }

    const renderName = async (id) => {
        try {
            const res = await getUserById(id);
            return res?.name;
        } catch (error) {
            console.log(error);
            return id;
        }
    }

    return (
        <div className='submit-assessment'>
            {contextHolder}
            <h2>Submit Assessment</h2>

            <p>{!assessmentData?.attempt ?
                <span>
                    You have not submitted this assessment yet. Please submit your assessment
                    {`before the due date: ${assessmentData?.endDate}`}
                </span>
                :
                <>
                    You have already attempted this assessment
                </>
            }
            </p>

            <h3>
                {`You have ${attempts === 0 ? 'No' : attempts} attempts remaining.`}
            </h3>

            <Divider />

            {assessmentData?.attempt?.id && <div className='attempt-data'>
                <h4>
                    Your submission details are as follows:
                </h4>
                <h3>
                    <span className='lable'>Attemped Date & Time: </span>
                    <span className='value'>{assessmentData?.attempt?.createdAt}</span>
                </h3>
                <h3>
                    <span className='lable'>
                        Submission URL:
                    </span>
                    <span className='value'>
                        <a
                            href={assessmentData?.attempt?.submissionUrl}
                            target='_blank'
                            rel='noreferrer'
                            className='value'>
                            {assessmentData?.attempt?.submissionUrl}
                        </a>
                    </span>
                </h3>
                <h3>
                    <span className='lable'>
                        Is Reviewed:
                    </span>
                    <span className='value'>
                        {assessmentData?.attempt?.isReviewed ? 'Yes' : 'No'}
                    </span>
                </h3>
                {assessmentData?.attempt?.isReviewed &&
                    <>
                        <h3>
                            <span className='lable'>
                                Reviewed Date:
                            </span>
                            <span className='value'>
                                {assessmentData?.attempt?.createdAt}
                            </span>
                        </h3>
                        <h3>
                            <span className='lable'>
                                Reviewed By:
                            </span>
                            <span className='value'>
                                {name}
                            </span>
                        </h3>
                        <h3>
                            <span className='lable'>
                                Marks:
                            </span>
                            <span className='value'>
                                {assessmentData?.attempt?.marks}
                            </span>
                        </h3>
                    </>
                }
                {(attempts > 0 && !assessmentData?.attempt?.isReviewed) &&
                    <>
                        <Divider />
                        <div className='re-submit'>
                            <h4>
                                If you want to re-submit the assessment, please provide the submission URL below:
                            </h4>
                            <p className='instruct'>
                                For quiz or test submissions, please include the email address used during the assessment in the designated submission URL field. If the submission pertains to a project or assignment, kindly provide the publicly accessible URL of the project or assignment in the specified submission URL field.
                            </p>
                            <Form
                                layout="vertical"
                                onFinish={onFinishReSubmit}
                                ref={formRef}
                            >
                                <Form.Item
                                    label='Submission URL'
                                    name="submissionUrl"
                                    rules={[{
                                        required: true,
                                        message: 'Please enter submission URL!'
                                    }]}>
                                    <Input
                                        placeholder='Please enter submission URL'
                                    />

                                </Form.Item>


                                <Button
                                    loading={isLoading}
                                    type="primary" htmlType="submit">
                                    Re-submit Assessment
                                </Button>

                            </Form>
                        </div>
                    </>}
            </div>
            }

            {!assessmentData?.attempt?.id &&
                <>
                    <h4>
                        Please Read the Instructions Carefully
                    </h4>
                    <p className='instruct'>
                        For quiz or test submissions, please include the email address used during the assessment in the designated submission URL field. If the submission pertains to a project or assignment, kindly provide the publicly accessible URL of the project or assignment in the specified submission URL field.
                    </p>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Form.Item
                            label='Submission URL'
                            name="submissionUrl"
                            rules={[{
                                required: true,
                                message: 'Please enter submission URL!'
                            }]}>
                            <Input
                                placeholder='Please enter submission URL'
                            />

                        </Form.Item>


                        <Button
                            loading={isLoading}
                            type="primary" htmlType="submit">
                            Submit Assessment
                        </Button>

                    </Form>
                </>}
        </div>
    )
}

export default SubmitAssessment