const BASE_URL = 'https://digitalacademy-backend.azurewebsites.net/api';

const BLOB_SAS_URL = 'https://digitalacademyblob.blob.core.windows.net/?sv=2022-11-02&ss=b&srt=sco&sp=rwdlaciytfx&se=2028-02-01T16:47:29Z&st=2024-04-15T08:47:29Z&spr=https&sig=PXflInl0xuyr2SdkW7bVShPgdvQ%2FKcf01pheAjYM41c%3D';

const USER_PROFILE_IMAGE_BLOB_BASE_URL = "https://digitalacademyblob.blob.core.windows.net/user-images/";

const DEFAULT_USER_PROFILE_IMAGE = 'https://digitalacademyblob.blob.core.windows.net/public/avatar.png';

const DEFAULT_COURSE_COVER_IMAGE = 'https://digitalacademyblob.blob.core.windows.net/public/courceImg.jpeg';

export {
    BASE_URL,
    BLOB_SAS_URL,
    USER_PROFILE_IMAGE_BLOB_BASE_URL,
    DEFAULT_USER_PROFILE_IMAGE,
    DEFAULT_COURSE_COVER_IMAGE,
}
