import { createSlice } from "@reduxjs/toolkit";

const usersCourcesReducer = createSlice({
    name: "usersCources",
    initialState: {
        usersCources: null,
        isLoading: false,
        error: null
    },
    reducers: {
        setUsersCources: (state, action) => {
            state.usersCources = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setUsersCources, setIsLoading, setError } = usersCourcesReducer.actions

export default usersCourcesReducer.reducer;