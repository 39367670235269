import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import { getUsersCourses, removeAUserFromACourse } from '../../../../../Constants/Api/functions/function';
import { Button, List, Popconfirm, Spin, notification } from 'antd';
import {
    PlusOutlined,
    ExportOutlined
} from '@ant-design/icons';

import './styles.scss'
import { DEFAULT_COURSE_COVER_IMAGE } from '../../../../../Constants/Api/azure/azure';
import AddUserCourse from './AddUserCourse';
import { useSelector } from 'react-redux';

const UserCources = () => {
    const [cources, setCources] = useState([])
    const userId = useParams().id
    const [isAddUserCourseOpen, setIsAddUserCourseOpen] = useState(false)
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const [isLoading, setIsLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (userId) {
            handleGetUserCources()
        }
    }, [userId])

    const handleGetUserCources = async () => {
        setIsLoading(true)
        try {
            const userCources = await getUsersCourses(userId)
            setCources(userCources)
        } catch (error) {
            console.error('handleGetUserCources', error)
        }
        setIsLoading(false)
    }

    const handleRemoveCourse = async (courseId) => {
        setIsLoading(true)
        try {
            const response = await removeAUserFromACourse(userId, courseId);
            if (response) {
                handleGetUserCources();
                api.success({
                    message: 'Course removed successfully'
                })
            }
        } catch (error) {
            console.error('handleDeleteUser', error);
            api.error({
                message: 'Failed to remove course'
            })
        }
        setIsLoading(false)
    }

    return (
        <div className='user-cources'>
            {contextHolder}
            <Spin
                spinning={isLoading}
                tip="Please wait..."
            >
                <div className='header'>
                    <h1>
                        User's Cources
                    </h1>
                    {userProfile?.role === 'superadmin' && <Button
                        type='primary'
                        shape='round'
                        style={{ width: '140px' }}
                        onClick={() => setIsAddUserCourseOpen(true)}
                    >
                        <PlusOutlined />
                        Add Course
                    </Button>
                    }

                    {userProfile?.role === 'student' &&
                        <Link to='/dashboard/cources'>
                            <Button
                                type='primary'
                                shape='round'
                                style={{ width: '140px' }}
                            >
                                <PlusOutlined />
                                Buy A Course
                            </Button>
                        </Link>
                    }
                </div>

                <div className='cources'>
                    <List
                        itemLayout='vertical'
                        size='large'
                        dataSource={cources}
                        renderItem={item => (
                            <List.Item
                                className='cource-item'
                                key={item.id}
                                actions={[
                                    <>
                                        <Link to={`/dashboard/cources/${item.id}`}>
                                            View <ExportOutlined />
                                        </Link>

                                        {userProfile?.role === 'superadmin' && <Popconfirm
                                            title='Are you sure you want to delete this course from user?'
                                            onConfirm={() => handleRemoveCourse(item.id)}
                                            okText='Yes'
                                            cancelText='No'
                                        >
                                            <Button
                                                danger
                                                type='link'>
                                                Remove Course
                                            </Button>
                                        </Popconfirm>}
                                    </>
                                ]}
                                extra={
                                    <img
                                        width={272}
                                        alt="logo"
                                        src={item?.coverImage || DEFAULT_COURSE_COVER_IMAGE}
                                    />
                                }
                            >
                                <List.Item.Meta
                                    title={<h2>{item.name}</h2>}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </div>

                <AddUserCourse
                    isOpen={isAddUserCourseOpen}
                    setOpen={setIsAddUserCourseOpen}
                    handleGetUserCources={handleGetUserCources}
                    userId={userId}
                    userCources={cources}
                />
            </Spin>
        </div>
    )
}

export default UserCources