import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSessions } from '../../../Constants/Api/functions/function';
import { setAllSessons } from '../../../Reducers/allSessonsReducer';

import { Link } from 'react-router-dom';

// Antd
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
} from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Tag } from 'antd';
import { useHistory } from 'react-router-dom';

const Attendance = () => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const searchInput = useRef(null);
    const [searchedColumn, setSearchedColumn] = useState('');
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allSessons = useSelector(state => state.allSessons.allSessons);
    const [sessions, setSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (userProfile?.role === 'student') {
            history.push(`/dashboard/home`)
        }
    }, [userProfile])

    useEffect(() => {
        if (sessions.length > 0) {
            let data = sessions.map((session, index) => {
                if (session?.isRepeat) {
                    const daysOfWeek = {
                        monday: 1,
                        tuesday: 2,
                        wednesday: 3,
                        thursday: 4,
                        friday: 5,
                        saturday: 6,
                        sunday: 7
                    };

                    let repeatDates = [];
                    let repeatDay = session?.repeatDays;
                    let createdDate = new Date(session?.createdAt);
                    let today = new Date();

                    let repeatedDaysUntilToday = Math.floor((today - createdDate) / (1000 * 60 * 60 * 24 * 7));
                    let repeatDayIndex = daysOfWeek[repeatDay];

                    for (let i = 0; i <= repeatedDaysUntilToday; i++) {
                        let date = new Date(createdDate);
                        date.setDate(date.getDate() + (repeatDayIndex + (i * 7) - date.getDay()));
                        repeatDates.push(date);
                    }

                    const res = repeatDates?.map((date, index) => {
                        const meet = {
                            key: index,
                            title: session?.name + ' - ' + date.toDateString(),
                            date: date.toDateString(),
                            id: session?.id,
                            isRepeat: session?.isRepeat,
                        }

                        return meet;
                    })

                    return res.map((meet, index) => {
                        return meet;
                    })
                } else {
                    return {
                        key: index,
                        title: session?.name,
                        date: session?.meetingDate || 'N/A',
                        id: session?.id,
                        isRepeat: session?.isRepeat,
                    }
                }
            });

            data = data.flat();
            setTableData(data);
        }
    }, [sessions])


    useEffect(() => {
        if (allSessons === null) {
            handleGetSessions();
        } else {
            let sortedSessons = sortMeetings(allSessons);
            setSessions(sortedSessons);
        }
    }, [allSessons])

    const handleGetSessions = async () => {
        setIsLoading(true);
        try {
            const response = await getSessions(userProfile?.role, userProfile?.id);
            let sortedSessons = sortMeetings(response);
            setSessions(sortedSessons);
            dispatch(setAllSessons(response));
        } catch (error) {
            console.error('handleGetSessions', error);
        }
        setIsLoading(false);
    }

    const sortMeetings = (meetings) => {
        let sortedMeetings = [...meetings];
        sortedMeetings.sort((a, b) => new Date(a.englishMeeetingDate) - new Date(b.englishMeeetingDate));
        return sortedMeetings;
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <>
                    {!record?.isRepeat
                        ?
                        <Link to={`/dashboard/attendance/${record?.id}`}>
                            <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[searchText]}
                                autoEscape
                                textToHighlight={text ? text.toString() : ''}
                            />
                        </Link>
                        :
                        <Link to={`/dashboard/attendance/${record?.id}@${record?.title}`}>
                            <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[searchText]}
                                autoEscape
                                textToHighlight={text ? text.toString() : ''}
                            />
                        </Link>
                    }
                </>
            ) : (
                <>
                    {!record?.isRepeat
                        ?
                        <Link to={`/dashboard/attendance/${record?.id}`}>
                            <p
                                style={{
                                    color: '#9373ff',
                                    cursor: 'pointer',
                                }}
                            >
                                {text}
                            </p>
                        </Link>
                        :
                        <Link to={`/dashboard/attendance/${record?.id}@${record?.title}`}>
                            <p
                                style={{
                                    color: '#9373ff',
                                    cursor: 'pointer',
                                }}
                            >
                                {text}
                            </p>
                        </Link>
                    }
                </>
            ),
    });

    const columns = [
        {
            title: 'Session Title',
            dataIndex: 'title',
            key: 'title',
            width: '70%',
            ...getNameSearchProps('name'),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '20%',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
        }
    ];

    return (
        <div>
            <div className='header'>
                <h1>
                    Attendance
                </h1>
            </div>
            <div className='table'>
                <Spin spinning={isLoading}>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        pagination={{
                            pageSize: 10,
                            position: ["bottomRight"],
                        }}
                    />
                </Spin>
            </div>
        </div>
    )
}

export default Attendance