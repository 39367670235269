import React, { useState } from 'react'

// Antd
import { Button, Col, Drawer, Form, Row, Select, notification, Spin } from 'antd';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';
import { useSelector } from 'react-redux';
import { addNote } from '../../../../Constants/Api/functions/function';

const { Option } = Select;

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

const AddNote = ({
    isOpen,
    setOpen,
    handleGetNotes,
    cources
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);

        if (values.isPublished === 'true') {
            values.isPublished = true;
        } else {
            values.isPublished = false;
        }

        if (values.isFree === 'true') {
            values.isFree = true;
        } else {
            values.isFree = false;
        }

        setIsLoading(true);

        try {
            const res = await addNote(values);
            if (res && res.status === 200) {
                openNotification('success',
                    'Success',
                    'New session added successfully'
                );
                setOpen(false);
                await handleGetNotes();
            } else {
                openNotification('error',
                    'Error',
                    res.data.message
                );
            }
        } catch (error) {
            console.error('AddCource', error);
            openNotification('error',
                'Error',
                'Something went wrong'
            );
        }

        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Scedule New Session"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Select Course"
                            name="courseId"
                            rules={[{ required: true, message: 'Please select course!' }]}>
                            <Select
                                placeholder="Please select course"
                            >
                                {cources?.map((cource, index) => (
                                    <Option
                                        key={index}
                                        value={cource.id}
                                    >
                                        {cource.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Note (Content Max: 1000)"
                            name="note"
                        >
                            <ReactQuill
                                modules={modules}
                                theme="snow"
                                placeholder="The content starts here..."
                                style={{
                                    height: '100%'
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="isFree"
                            label="Is Free"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select is free",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select is free"
                            >
                                <Option value="true">
                                    Free
                                </Option>
                                <Option value="false">
                                    Not Free
                                </Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="isPublished"
                            label="Is Published"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select is published",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select is published"
                            >
                                <Option value="true">
                                    Published
                                </Option>
                                <Option value="false">
                                    Not Published
                                </Option>
                            </Select>
                        </Form.Item>

                        <Row gutter={16}>
                            <Col>
                                <Button
                                    style={{ width: '100%' }}
                                    type="primary" htmlType="submit">
                                    Add Note
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddNote