import React, { useState } from 'react'

import { useSelector } from 'react-redux';

// Antd
import { Button, Drawer, Form, Input, Select, notification, Spin } from 'antd';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';

import './style.scss';
import { addAssestment } from '../../../../Constants/Api/functions/function';


const { Option } = Select;

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

const AddAssessment = ({
    isOpen,
    setOpen,
    handleGetAssessments,
    cources
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);
        values.createdBy = userProfile.id;

        setIsLoading(true);

        try {
            const res = await addAssestment(values);
            if (res && res.status === 200) {
                openNotification('success',
                    'Success',
                    'New assessment has been added successfully.'
                );
                setOpen(false);
                await handleGetAssessments();
            } else {
                openNotification('error',
                    'Error',
                    res.data.message
                );
            }
        } catch (error) {
            console.error('AddAssessment', error);
            openNotification('error',
                'Error',
                'Something went when adding new assessment.'
            );
        }

        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Create a new assessment"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}
            >
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Select Course"
                            name="courseId"
                            rules={[{ required: true, message: 'Please select course!' }]}>
                            <Select
                                placeholder="Please select course"
                            >
                                {cources?.map((cource, index) => (
                                    <Option
                                        key={index}
                                        value={cource.id}
                                    >
                                        {cource.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Assessment Name"
                            name="name"
                            rules={[{ required: true, message: 'Please enter assessment name!' }]}>
                            <Input
                                placeholder="Please enter assessment name"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Assessment Description"
                            name="description"
                            rules={[{
                                required: true,
                                message: 'Please enter assessment description!'
                            }]}>
                            <ReactQuill
                                modules={modules}
                                theme="snow"
                                placeholder="The description of the assessment"
                                style={{
                                    height: '100%',
                                    zIndex: 10000,
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Assessment URL"
                            name="assessmentUrl"
                            rules={[{ required: true, message: 'Please enter assessment URL!' }]}>
                            <Input
                                placeholder="Please enter assessment URL"
                            />
                        </Form.Item>

                        <Form.Item
                            label="End Date"
                            name="endDate"
                            rules={[{
                                required: true,
                                message: 'Please select end date!'
                            }]}>
                            <Input
                                type="date"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Is Published"
                            name="isPublished"
                            rules={[{
                                required: true,
                                message: 'Please select is published!'
                            }]}>
                            <Select
                                placeholder="Please select is published"
                            >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>

                        <Button
                            type="primary" htmlType="submit">
                            Create Assessment
                        </Button>

                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddAssessment