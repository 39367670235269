import {
    HomeOutlined,
    TeamOutlined,
    BookOutlined,
    UsergroupAddOutlined,
    FileDoneOutlined,
    DollarOutlined,
    SnippetsOutlined,
    StarOutlined
} from "@ant-design/icons";

const SUPER_ADMIN_MENU_ITEMS = [
    { key: "1", icon: <HomeOutlined />, label: "Home", href: "/dashboard/home" },
    { key: "2", icon: <TeamOutlined />, label: "Users", href: "/dashboard/users" },
    { key: "3", icon: <BookOutlined />, label: "Courses", href: "/dashboard/cources" },
    { key: "4", icon: <FileDoneOutlined />, label: "Assessments", href: "/dashboard/assessments" },
    { key: "5", icon: <UsergroupAddOutlined />, label: "Attendance", href: "/dashboard/attendance" },
    { key: "6", icon: <DollarOutlined />, label: "Payments", href: "/dashboard/payments" },
    { key: "7", icon: <SnippetsOutlined />, label: "Notes", href: "/dashboard/notes" },
    { key: "9", icon: <StarOutlined />, label: "Wish List", href: "/dashboard/wishlist" },
]

const STUDENT_MENU_ITEMS = [
    { key: "1", icon: <HomeOutlined />, label: "Home", href: "/dashboard/home" },
    { key: "3", icon: <BookOutlined />, label: "Courses", href: "/dashboard/cources" },
    { key: "8", icon: <UsergroupAddOutlined />, label: "My Cources", href: "/dashboard/mycources" },
    { key: "4", icon: <FileDoneOutlined />, label: "Assessments", href: "/dashboard/assessments" },
    { key: "9", icon: <StarOutlined />, label: "Wish List", href: "/dashboard/wishlist" },
]

export {
    SUPER_ADMIN_MENU_ITEMS,
    STUDENT_MENU_ITEMS,
}
