import React, { useEffect, useState } from 'react'
import { Button, List, Popconfirm, Spin, notification } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { deleteNote, getAllCoursesByRole, getNotesByUser } from '../../../Constants/Api/functions/function'
import { setAllCources } from '../../../Reducers/allCourcesReducer'
import ReactQuill from 'react-quill'
import AddNote from './AddNote'

const Notes = () => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(true)
    const [notes, setNotes] = useState()
    const [cources, setCources] = useState()
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allCources = useSelector(state => state.allCources.allCources);
    const [isAddNoteOpen, setIsAddNoteOpen] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        handleGetNotes()
    }, [userProfile])

    useEffect(() => {
        if (!allCources) {
            hadleGetCources();
        } else {
            setCources(allCources);
        }
    }, [allCources])


    const handleGetNotes = async () => {
        setIsLoading(true)
        try {
            const response = await getNotesByUser(userProfile.id, userProfile.role)
            setNotes(response)
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    }

    const hadleGetCources = async () => {
        if (userProfile && userProfile.role === 'superadmin') {
            try {
                const response = await getAllCoursesByRole('superadmin');
                dispatch(setAllCources(response));
                setCources(response);
            } catch (error) {
                console.error('hadleGetCources', error);
            }
        }
    }

    const handleDeleteNote = async (id) => {
        setIsLoading(true)
        try {
            const response = await deleteNote(id)
            if (response) {
                handleGetNotes()
                openNotification('success', 'Success', 'Note deleted successfully')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div className='notes-page'>
            {contextHolder}
            <div className='notes-header'>
                <h1>Notes</h1>

                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => setIsAddNoteOpen(true)}
                >
                    Add Note
                </Button>
            </div>

            <Spin spinning={isLoading}>
                <div className='notes-list'>
                    <List
                        itemLayout='horizontal'
                        dataSource={notes}
                        renderItem={item => (
                            <List.Item
                                className='note-item'
                            >
                                <div className='note-header'>
                                    <h3><span>ID: </span>{item?.id}</h3>

                                    <div className='note-actions'>
                                        <Popconfirm
                                            title="Are you sure to delete this note?"
                                            onConfirm={() => handleDeleteNote(item?.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type='link'
                                                danger
                                            >
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                                <div className='note-container'>
                                    <ReactQuill
                                        className='note-description'
                                        readOnly
                                        theme="bubble"
                                        value={item?.note}
                                    />
                                </div>
                                <h3>
                                    <span>Course: </span> {cources?.find(cource => cource.id === item?.courseId)?.name}
                                </h3>
                                <h3>
                                    <span>Created At: </span> {item?.createdAt}
                                </h3>
                                <h3>
                                    <span>Is Published: </span> {item?.isPublished ? 'Yes' : 'No'}
                                </h3>
                                <h3>
                                    <span>Is Free: </span> {item?.isFree ? 'Yes' : 'No'}
                                </h3>
                            </List.Item>
                        )}
                    />
                </div>
            </Spin>

            <AddNote
                isOpen={isAddNoteOpen}
                setOpen={setIsAddNoteOpen}
                handleGetNotes={handleGetNotes}
                cources={cources}
            />
        </div>
    )
}

export default Notes