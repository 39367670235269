import React, { useState } from 'react'

// Antd
import { Button, Col, Drawer, Form, Input, Row, Select, notification, Spin, Radio } from 'antd';

import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { addCourseContent } from '../../../../../../Constants/Api/functions/function';

const { Option } = Select;

const AddContent = ({
    isOpen,
    setOpen,
    courseId,
    hadleGetCourceContents
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);
        values.createdBy = userProfile.id;

        if (values.isPublished === 'true') {
            values.isPublished = true;
        } else {
            values.isPublished = false;
        }

        if (values.isFree === true) {
            values.isFree = true;
        } else {
            values.isFree = false;
        }

        values.courseId = courseId;

        setIsLoading(true);

        try {
            const res = await addCourseContent(values);
            if (res && res.status === 200) {
                openNotification('success',
                    'Course content added',
                    'New course content added successfully'
                );
                setOpen(false);
                await hadleGetCourceContents();
            }
        } catch (error) {
            console.error('AddCource', error);
        }

        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add new course content"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Content Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input the title!' }]}>
                            <Input
                                showCount
                                maxLength={200}
                                placeholder="Content Title" />
                        </Form.Item>
                        <Form.Item
                            label="Content Description"
                            name="description">
                            <TextArea
                                showCount
                                maxLength={1000}
                                autoSize={{ minRows: 3, maxRows: 10 }}
                                placeholder="Content Description" />
                        </Form.Item>
                        <Form.Item
                            label="Video ID"
                            name="videoUrl">
                            <Input
                                showCount
                                maxLength={500}
                                placeholder="Video ID" />
                        </Form.Item>
                        <Form.Item
                            label="Drive URL"
                            name="driveUrl">
                            <Input
                                showCount
                                maxLength={500}
                                placeholder="Drive URL" />
                        </Form.Item>
                        <Form.Item
                            label="Is Free"
                            name="isFree"
                            rules={[{ required: true, message: 'Please check isFree!' }]}
                        >
                            <Radio.Group>
                                <Radio value={true}>Free</Radio>
                                <Radio value={false}>Paid</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Is Published"
                            name="isPublished"
                            rules={[{ required: true, message: 'Please check isPublished!' }]}
                        >
                            <Select
                                placeholder="Please select course content published status"
                            >
                                <Option value="true">Published</Option>
                                <Option value="false">Not Published</Option>
                            </Select>
                        </Form.Item>

                        <Row gutter={16}>
                            <Col>
                                <Button
                                    style={{ width: '100%' }}
                                    type="primary" htmlType="submit">
                                    Add Content
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddContent