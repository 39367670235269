import { createSlice } from '@reduxjs/toolkit';

const allNotesReducer = createSlice({
    name: 'allNotes',
    initialState: {
        allNotes: null,
        isLoading: false,
        error: null
    },
    reducers: {
        setAllNotes: (state, action) => {
            state.allNotes = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setAllNotes, setIsLoading, setError } = allNotesReducer.actions

export default allNotesReducer.reducer