import React from 'react'

// Antd
import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';

import Home from '../../Home';
import Cources from '../../Cources';
import users from '../../Users';

import './style.scss';
import Course from '../../Cources/Course';
import EditCourse from '../../Cources/Course/EditCourse';
import User from '../../Users/User';
import Payments from '../../Payments';
import Assessments from '../../Assessments';
import Assessment from '../../Assessments/Assessment';
import BuyCourse from '../../Cources/Course/BuyCourse';
import Attendance from '../../Attendance';
import SessionDetails from '../../Attendance/SessionDetails';
import Notes from '../../Notes';
import MyCources from '../../MyCources';
import WishList from '../../WishList';
import { useSelector } from 'react-redux';
import WishListAdmin from '../../WishListAdmin';

const { Content } = Layout;

const DashboardContent = () => {
    const userProfile = useSelector(state => state.userProfile.userProfile);

    return (
        <Content className='dashboard-content'>
            <div className='site-layout-background'>
                <Switch>
                    <Route path='/dashboard/home' component={Home} />
                    <Route path='/dashboard/profile' component={User} />
                    <Route path='/dashboard/users/:id' component={User} />
                    <Route path='/dashboard/users' component={users} />

                    <Route path='/dashboard/cources/:id/buy' component={BuyCourse} />
                    <Route path='/dashboard/cources/:id/edit' component={EditCourse} />
                    <Route path='/dashboard/cources/:id' component={Course} />
                    <Route path='/dashboard/cources' component={Cources} />

                    <Route path='/dashboard/mycources/:id' component={Course} />
                    <Route path='/dashboard/mycources' component={MyCources} />

                    <Route path='/dashboard/payments' component={Payments} />

                    <Route path='/dashboard/assessments/:id' component={Assessment} />
                    <Route path='/dashboard/assessments' component={Assessments} />

                    <Route path='/dashboard/attendance/:id' component={SessionDetails} />

                    <Route path='/dashboard/attendance' component={Attendance} />
                    <Route path='/dashboard/notes' component={Notes} />

                    <Route path='/dashboard/wishlist' component={userProfile?.role === 'student' ? WishList : WishListAdmin} />
                </Switch>
            </div>
        </Content>
    )
}

export default DashboardContent