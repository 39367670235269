import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Tabs } from 'antd';

import { useHistory } from 'react-router-dom';

import UserOverview from './UserOverview';
import { useParams } from 'react-router-dom';
import UserProgress from './UserProgress';
import { getAllUsers } from '../../../../Constants/Api/functions/function';
import { setAllUsers } from '../../../../Reducers/allUsersReducer';
import UserCources from './UserCources';
import UserPayments from './UserPayments';

const User = () => {
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedTab, setSelectedTab] = useState('1');
    const [items, setItems] = useState([]);
    const allUsers = useSelector((state) => state.allUsers.allUsers);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const userId = useParams().id;
    const dispatch = useDispatch();
    const history = useHistory();
    const url = history.location.pathname;

    useEffect(() => {
        if (url.includes('overview')) {
            setSelectedTab('1')
        } else if (url.includes('progress')) {
            setSelectedTab('2')
        } else if (url.includes('courses')) {
            setSelectedTab('3')
        } else if (url.includes('payments')) {
            setSelectedTab('4')
        }
    }, [url])

    useEffect(() => {
        if ((userProfile?.role === 'student' || userProfile?.role === 'teacher') && userId !== userProfile.id) {
            history.push(`/dashboard/users/${userProfile.id}/overview`)
        }
    }, [userId, userProfile])

    useEffect(() => {
        setItems([
            {
                key: '1',
                label: 'Overview',
                children: <UserOverview
                    selectedUser={selectedUser}
                />,
            },
            {
                key: '2',
                label: 'Progress',
                children: <UserProgress user={selectedUser} />,
            },
            {
                key: '3',
                label: 'Courses',
                children: <UserCources user={selectedUser} />,
            },
            {
                key: '4',
                label: 'Payments',
                children: <UserPayments selectedUser={selectedUser} />,
            }
        ])
    }, [])

    useEffect(() => {
        if (allUsers.length) {
            const user = allUsers.find((user) => user.id === userId);
            setSelectedUser(user);
        } else {
            handleGetAllUsers();
        }

    }, [allUsers])


    const handleGetAllUsers = async () => {
        try {
            const response = await getAllUsers();
            const users = response.find((user) => user.id === userId);
            setSelectedUser(users);
            if (response) {
                dispatch(setAllUsers(response))
            }
        } catch (error) {
            console.error('getAllUsers', error);
        }
    }

    const onChange = (key) => {
        const { label } = items.find((item) => item.key === key);
        setSelectedTab(key);
        history.push(`/dashboard/users/${userId}/${label.toLowerCase()}`)
    };

    return (
        <div className='view-user'>
            <Tabs
                items={items}
                onChange={onChange}
                activeKey={selectedTab}
                style={{ height: '100%' }}
            />
        </div>
    )
}

export default User