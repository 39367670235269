import React, { useState } from 'react'

// Antd
import { Button, Col, Drawer, Form, Input, Row, Select, notification, Spin, message, Radio, Upload } from 'antd';

import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { addCourse } from '../../../../Constants/Api/functions/function';
import { BLOB_SAS_URL } from '../../../../Constants/Api/azure/azure';
import { BlobServiceClient } from '@azure/storage-blob';

const { Option } = Select;

const AddCource = ({
    isOpen,
    setOpen,
    hadleGetCources
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const [fileList, setFileList] = useState([]);

    const onFinish = async (values) => {
        setIsLoading(true);

        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);
        values.createdBy = userProfile.id;

        if (values.isPublished === 'true') {
            values.isPublished = true;
        } else {
            values.isPublished = false;
        }

        if (values.isAppearOnHome === true) {
            values.isAppearOnHome = true;
        } else {
            values.isAppearOnHome = false;
        }

        if (values.isShowOnHome === 'true') {
            values.isShowOnHome = true;
        } else {
            values.isShowOnHome = false;
        }

        if (values.tags) {
            values.tags = values.tags.join(',');
        }

        if (fileList.length < 1) {
            openNotification('error',
                'Upload course cover image',
                'Please upload course cover image to continue'
            );
            setIsLoading(false);
            return;
        }

        const blobName = `${values.name.replace(/\s/g, '')}.png`;
        const res = await uploadFile(blobName);
        values.coverImage = res?.blockBlobClient?.url;

        try {
            const res = await addCourse(values);
            if (res && res.status === 200) {
                openNotification('success',
                    'Course added successfully',
                    'New course has been added successfully'
                );
                setOpen(false);
                await hadleGetCources();
            }
        } catch (error) {
            console.error('AddCource', error);
        }

        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    const uploadFile = async (blobName) => {
        try {
            const blobSasUrl = BLOB_SAS_URL;
            const container = 'course-thumbnails';

            const containerClient = new BlobServiceClient(blobSasUrl).getContainerClient(container);

            const res = await containerClient.uploadBlockBlob(
                `${blobName}`,
                fileList[0].originFileObj,
                fileList[0].size
            );
            return res
        } catch (error) {
            console.log("error", error)
        }
    }

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add new course"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip="Adding new course..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="name"
                            label="Course Name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter course's name",
                                },
                            ]}
                        >
                            <Input
                                showCount
                                maxLength={200}
                                placeholder="Please enter course's name" />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="Course Description"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter course's discription",
                                },
                            ]}
                        >
                            <TextArea
                                showCount
                                maxLength={1000}
                                autoSize={{ minRows: 3, maxRows: 10 }}
                                placeholder="Please enter course's discription" />
                        </Form.Item>
                        <Form.Item
                            name="tags"
                            label="Select course tags"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select course's tags",
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                options={[
                                    {
                                        label: 'Video Tutorials',
                                        value: 'VideoTutorials'
                                    },
                                    {
                                        label: 'Exams',
                                        value: 'Exams'
                                    },
                                    {
                                        label: 'Training and Courses',
                                        value: 'TrainingAndCourses'
                                    },
                                    {
                                        label: 'News',
                                        value: 'News'
                                    },
                                    {
                                        label: 'Seminar',
                                        value: 'Seminar'
                                    }
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            name="orderNumber"
                            label="Order Number (The order of the course when displaying)"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter course's order number",
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder="Please enter course's order number" />
                        </Form.Item>
                        <Form.Item
                            name="coverImage"
                            label="Course cover image"
                            rules={[
                                {
                                    required: true,
                                    message: "Please upload course's cover image",
                                },
                            ]}
                        >
                            <Upload
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                                beforeUpload={file => {
                                    const isPNG = (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg');
                                    if (!isPNG) {
                                        message.error(`${file.name} is not a png file`);

                                        return isPNG || Upload.LIST_IGNORE;
                                    } else {
                                        return false;
                                    }
                                }}
                            >
                                {fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name='startDate'
                            label="Course Start Date"
                        >
                            <Input
                                type="date"
                                placeholder="Please enter course's start date" />
                        </Form.Item>

                        <Form.Item
                            name='startTime'
                            label="Course Start Time"
                        >
                            <Input
                                type="time"
                                placeholder='Please enter course start time'
                            />
                        </Form.Item>

                        <Form.Item
                            name='installments'
                            label="Number of Installments"
                        >
                            <Input
                                type="number"
                                placeholder='Please enter number of installments'
                            />
                        </Form.Item>

                        <Form.Item
                            name='isShowOnHome'
                            label="Show on Home Page"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select course's show on home page status",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select course's show on home page status"
                            >
                                <Option value="true">Yes</Option>
                                <Option value="false">No</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name='description1'
                            label="Description 1"
                        >
                            <TextArea
                                showCount
                                maxLength={1000}
                                autoSize={{ minRows: 3, maxRows: 10 }}
                                placeholder="Please enter course's discription" />
                        </Form.Item>

                        <Form.Item
                            name='whatsappGroupLink'
                            label="Whatsapp Group Link"
                        >
                            <Input
                                placeholder='Please enter whatsapp group link'
                            />
                        </Form.Item>

                        <Form.Item
                            name='videoUrl'
                            label="Video URL"
                        >
                            <Input
                                placeholder='Please enter video url'
                            />
                        </Form.Item>

                        <Form.Item
                            name="isPublished"
                            label="Course is Published"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select course's published status",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select course's published status"
                            >
                                <Option value="true">Published</Option>
                                <Option value="false">Not Published</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="price"
                            label="Course Price (LKR)"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter course's price",
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder="Please enter course's price" />
                        </Form.Item>

                        <Form.Item
                            name="isAppearOnHome"
                            label="Appear on Home Page"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select course's appear on home page status",
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={true}>
                                    Yes
                                </Radio>
                                <Radio value={false}>
                                    No
                                </Radio>
                            </Radio.Group>
                        </Form.Item>


                        <Row gutter={16}>
                            <Col span={12}>
                                <Button type="primary" htmlType="submit">
                                    Add Course
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddCource