import { createSlice } from '@reduxjs/toolkit';

const userProfileReducer = createSlice({
    name: 'userProfile',
    initialState: {
        userProfile: {},
        isLoading: false,
        error: null
    },
    reducers: {
        setUserProfile: (state, action) => {
            state.userProfile = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setUserProfile, setIsLoading, setError } = userProfileReducer.actions

export default userProfileReducer.reducer