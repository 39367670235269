import { List, Spin } from 'antd'
import React, { useEffect, useState } from 'react'

import './styles.scss'
import { getAllCoursesByRole, getPaymentsByUserId, getUserById } from '../../../../../Constants/Api/functions/function'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const UserPayments = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [payments, setPayments] = useState([])
    const [cources, setCources] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const allCources = useSelector(state => state.allCources.allCources);
    const allUsers = useSelector((state) => state.allUsers.allUsers);
    const userId = useParams().id;

    useEffect(() => {
        if (allUsers.length) {
            const user = allUsers.find((user) => user.id === userId);
            setSelectedUser(user);
        } else {
            handleGetUserById(userId);
        }
    }, [allUsers])

    const handleGetUserById = async (userId) => {
        setIsLoading(true)
        try {
            const user = await getUserById(userId);
            setSelectedUser(user);
        } catch (error) {
            console.error('handleGetUserById', error);
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (allCources) {
            setCources(allCources)
        } else {
            handleGetCourses();
        }
    }, [allCources]);

    const handleGetCourses = async () => {
        try {
            const courses = await getAllCoursesByRole('superadmin');
            setCources(courses);
        } catch (error) {
            console.error('handleGetCourses', error);
        }
    }

    useEffect(() => {
        if (selectedUser) {
            handleGetPayments()
        }
    }, [selectedUser])

    const handleGetPayments = async () => {
        setIsLoading(true)
        try {
            let userPayments = await getPaymentsByUserId(selectedUser.id)
            setPayments(userPayments)
        } catch (error) {
            console.error('handleGetPayments', error)
        }
        setIsLoading(false)
    }

    return (
        <div className='user-payments'>
            <Spin
                spinning={isLoading}
                tip="Please wait..."
            >
                <div className='header'>
                    {selectedUser && <h1>
                        {`${selectedUser?.name}'s Payments`}
                    </h1>}
                </div>

                <div className='payments'>
                    <List
                        itemLayout="horizontal"
                        dataSource={payments}
                        renderItem={item => (
                            <List.Item
                                key={item.id}
                                className='payment-item'
                            >
                                <div className='payment'>
                                    <h3>
                                        {cources.find((course) => course.id === item.courseId)?.name}
                                    </h3>
                                    <p>
                                        {`Payment ID: ${item.id}`}
                                    </p>
                                    <p>
                                        {`Amount: ${item.amount} LKR`}
                                    </p>
                                    <p>
                                        {`Date: ${item.createdAt}`}
                                    </p>
                                    <p>
                                        {`Method: ${item.paymentMethod}`}
                                    </p>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default UserPayments