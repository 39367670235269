import React, { useState } from "react";
import {
    Button,
    Form,
    Input,
    notification,
    Spin,
} from "antd";
import {
    EyeTwoTone,
    EyeInvisibleOutlined,
    CheckOutlined
} from '@ant-design/icons';

// API
import { requestPasswordReset, resetPassword, checkForPasswordReset } from '../../Constants/Api/functions/function';

import signinBg from '../../Assets/Images/signinBg.png'
import signin from '../../Assets/Images/signin.png'

import './style.scss'


const FogetPassword = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [api, contextHolder] = notification.useNotification();
    const [isRequestSent, setIsRequestSent] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isVerifing, setIsVerifing] = useState(false);
    const [isReseted, setIsReseted] = useState(false);

    const onFinishRequest = async (values) => {
        await handleSendResetRequest(values)
    };

    const onFinishReset = async (values) => {
        await handleResetPassword(values)
    }

    const onFinishVerify = async (values) => {
        setIsVerifing(true)
        const response = await checkForPasswordReset(email, values.confirmationCode)

        if (response?.status === 200) {
            setIsVerified(true)
            setIsVerifing(false)
        }

        if (response?.status === 404) {
            setIsVerifing(false)
            invalidCodeNotification()
        }
    }

    const handleResetPassword = async (values) => {
        setIsLoading(true)
        values = {
            email: email,
            password: values.password
        }

        const response = await resetPassword(values)

        if (response?.status === 200) {
            setIsLoading(false)
            setIsReseted(true)

            setTimeout(() => {
                window.location.href = '/login'
            }, 5000)
        }
        else {
            resetPasswordNotification('error')
            setIsLoading(false)
        }
    }

    const handleSendResetRequest = async (user) => {
        setIsLoading(true)
        const response = await requestPasswordReset(user.email)
        setIsLoading(false)

        if (response?.status === 200) {
            setEmail(user.email)
            setIsRequestSent(true)
        } else if (response?.status === 404) {
            userNotFoundNotification()
        } else {
            openNotificationWithIcon('error')
        }
    }

    const openNotificationWithIcon = (type) => {
        api[type]({
            message: {
                success: 'Password Reset Code Sent',
                error: 'Error'
            }[type],
            description: {
                success: 'Please check your email for the password reset code.',
                error: 'Something went wrong. Please try again',
            }[type],
        });
    };

    const resetPasswordNotification = (type) => {
        api[type]({
            message: {
                success: 'Password Reset Successful',
                error: 'Error'
            }[type],
            description: {
                success: 'Your password has been reset successfully. You can now sign in with your new password.',
                error: 'Something went wrong. Please try again',
            }[type],
        });
    };

    const invalidCodeNotification = () => {
        api['error']({
            message: 'Invalid Code',
            description: 'The code you entered is invalid. Please check the code and try again.',
        });
    }

    const userNotFoundNotification = () => {
        api['error']({
            message: 'User not found',
            description: 'Please check the email address and try again.',
        });
    }

    return (
        <div className='foget-password-page'
            style={{
                backgroundImage: `url(${signinBg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}>
            {contextHolder}
            <section className="form">
                <div className="form-container">
                    <div className="signin-top"
                        style={{
                            backgroundImage: `url(${signin})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }}
                    >
                        <h1 className="title">
                            {
                                !isReseted ? 'Reset your account password' : "Your password has been reset"
                            }
                        </h1>
                    </div>
                    {!isReseted ?
                        <Spin
                            spinning={isLoading}
                            tip="Please wait..."
                        >
                            {!isRequestSent ?
                                <Form
                                    layout="vertical"
                                    onFinish={onFinishRequest}
                                >
                                    <Form.Item
                                        className='form-item'
                                        label="Email Address"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Email address!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The input is not a valid email address!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input placeholder="sampleuser@gmail.com" />
                                    </Form.Item>

                                    <Form.Item className='form-bottom'>
                                        <Button
                                            className='form-button'
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Send Reset Code
                                        </Button>

                                        <Button
                                            className='fogot-button'
                                            type="link"
                                            onClick={() => window.location.href = '/login'}
                                        >
                                            Go back to sign in
                                        </Button>
                                    </Form.Item>
                                </Form>
                                :
                                <>
                                    <p className="instruction">
                                        Please enter the confirmation code sent to your email and set a new password. Please check your spam folder if you do not see the email in your inbox.
                                    </p>
                                    <Form
                                        className="verify-form"
                                        layout="vertical"
                                        onFinish={onFinishVerify}
                                    >
                                        <Form.Item
                                            className='form-item'
                                            label="Confirmation Code"
                                            name="confirmationCode"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter the confirmation code sent to your email!',
                                                }
                                            ]}
                                            style={{
                                                width: '100%'
                                            }}
                                        >
                                            <Input
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="XXXXXX" />
                                        </Form.Item>

                                        <Form.Item>
                                            {!isVerified ?
                                                <Button
                                                    className='form-button-verify'
                                                    type="primary"
                                                    htmlType="submit"
                                                    loading={isVerifing}
                                                >
                                                    Verify
                                                </Button>
                                                :
                                                <Button
                                                    className='form-button-verify verified'
                                                    type="primary"
                                                    htmlType="submit"
                                                    loading={isVerifing}
                                                    disabled
                                                    icon={<CheckOutlined />}
                                                >
                                                    Verified
                                                </Button>
                                            }
                                        </Form.Item>

                                    </Form>

                                    <Form
                                        layout="vertical"
                                        onFinish={onFinishReset}
                                        disabled={!isVerified}
                                    >

                                        <Form.Item
                                            className='form-item'
                                            name="password"
                                            label="New Password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter new password!',
                                                },
                                                { min: 6 },
                                            ]}
                                        >
                                            <Input.Password
                                                placeholder="Input new password"
                                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                            />
                                        </Form.Item>


                                        <Form.Item
                                            className='form-item'
                                            label="Confirm New Password"
                                            name="conPassword"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please confirm your new password!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password
                                                placeholder="Input password"
                                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                            />
                                        </Form.Item>

                                        <Form.Item className='form-bottom'>
                                            <Button
                                                className='form-button'
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Reset Password
                                            </Button>

                                            <Button
                                                className='fogot-button'
                                                type="link"
                                                onClick={() => window.location.href = '/login'}
                                            >
                                                Go back to sign in
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </>
                            }
                        </Spin>
                        :
                        <div className="after-reset">
                            <h5>
                                Your password has been reset successfully. You can now sign in with your new password.
                            </h5>
                            <Button
                                className='form-button'
                                type="primary"
                                onClick={() => window.location.href = '/login'}
                            >
                                Sign in
                            </Button>
                            <p>
                                You will be redirected to the sign in page in 5 seconds.
                            </p>
                        </div>
                    }
                </div>
            </section>
        </div>
    )
}
export default FogetPassword