import React from 'react'

// Antd
import { Avatar, Layout, theme, Tooltip, Space, Button } from 'antd';
import {
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';

// Router
import { Link } from 'react-router-dom';

import './style.scss'
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfile } from '../../../../Reducers/userProfileReducer';

import { DEFAULT_USER_PROFILE_IMAGE } from '../../../../Constants/Api/azure/azure';

const { Header } = Layout;

const items = [
    {
        label:
            <Link to='/dashboard/profile'>
                <Button
                    type='link'
                >
                    <UserOutlined /> View Profile
                </Button>
            </Link>,
        key: '0',
    },
    {
        label: <Button
            type='link'
        >
            <LogoutOutlined /> Sign Out
        </Button>,
        key: '1',
    },
];

const DashboardHeader = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const userProfile = useSelector((state) => state.userProfile?.userProfile)
    const dispatch = useDispatch();

    const handleSignOut = async () => {
        localStorage.removeItem('digital-academy-user')
        dispatch(setUserProfile(null))
        window.location.href = '/login'
    }

    const getIndexNumber = (name, index) => {
        const firstLetter = name?.charAt(0).toUpperCase();
        if (firstLetter && index) {
            return `${firstLetter}${index}`
        } else {
            return 'Please wait...'
        }
    }

    return (
        <Header
            className='dashboard-header'
            style={{
                padding: 0,
                background: colorBgContainer,
            }}
        >
            <Tooltip
                overlayClassName='user-profile-tooltip'
                color='white'
                placement="bottom"
                trigger={['click']}
                title={
                    <Space direction="vertical">
                        {
                            items.map((item) => {
                                return (
                                    <div
                                        key={item.key}
                                        onClick={() => {
                                            if (item.key === '1') {
                                                handleSignOut()
                                            }
                                        }}
                                    >
                                        {item.label}
                                    </div>
                                );
                            })
                        }
                    </Space>
                }
            >
                <div className='profile-bar'>
                    <Avatar
                        size="large"
                        src={userProfile?.profileImage ? userProfile?.profileImage : DEFAULT_USER_PROFILE_IMAGE}
                    />
                    <div className='profile-name'>
                        <p>
                            {userProfile?.name || 'User'}
                        </p>
                        <h5>
                            {userProfile?.indexNumber ? getIndexNumber(userProfile?.name, userProfile?.indexNumber) : 'Index Number'}
                        </h5>
                    </div>
                </div>
            </Tooltip>
        </Header>
    )
}

export default DashboardHeader