import React, { useEffect, useState } from 'react'
import { format, nextDay } from 'date-fns';

import {
    Button,
    List,
    Spin,
    message,
    Divider,
    Popconfirm,
    notification,
    Tag
} from 'antd'
import {
    PlusOutlined,
    CalendarOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    WhatsAppOutlined
} from '@ant-design/icons'

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setAllCources } from '../../../Reducers/allCourcesReducer';
import { setAllSessons } from '../../../Reducers/allSessonsReducer';

// API 
import { attendToSession, deleteSession, getAllCoursesByRole, getDashboardHomeCources, getNotesByUser, getSessions } from '../../../Constants/Api/functions/function';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';

import { Link } from 'react-router-dom';

import './style.scss'
import AddSession from './AddSession';
import EditSession from './EditSession';

import { DEFAULT_USER_PROFILE_IMAGE } from '../../../Constants/Api/azure/azure';

const Home = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [api, contextHolderNoti] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false)
    const [loadingNote, setLoadingNote] = useState(false)
    const [isAddSessionOpen, setIsAddSessionOpen] = useState(false)
    const [isEditSessionOpen, setIsEditSessionOpen] = useState(false)
    const [selectedSession, setSelectedSession] = useState()
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const [cources, setCources] = useState();
    const [homeCourses, setHomeCourses] = useState();
    const [notes, setNotes] = useState();
    const [sessions, setSessions] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        hadleGetCources();
        handleGetSessions();
        handleGetNote();
        handleGetHomeCourses();
    }, [])

    const handleGetHomeCourses = async () => {
        try {
            const response = await getDashboardHomeCources();
            setHomeCourses(response);
        } catch (error) {
            console.error('handleGetHomeCourses', error);
        }
    }

    const hadleGetCources = async () => {
        if (userProfile && userProfile.role === 'superadmin') {
            try {
                const response = await getAllCoursesByRole('superadmin');
                dispatch(setAllCources(response));
                setCources(response);
            } catch (error) {
                console.error('hadleGetCources', error);
            }
        }
    }

    const getNextWeekdayDate = (weekday) => {
        const today = new Date();
        const daysOfWeek = {
            sunday: 0,
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6
        };
        const targetDay = daysOfWeek[weekday];

        const todayDay = today.getDay();
        const weekDayNum = daysOfWeek[weekday];

        if (todayDay === weekDayNum) {
            return `Today & every ${weekday}`
        } else {
            return `Every ${weekday} This week: ${format(nextDay(today, targetDay), 'dd/MM/yyyy')}`;
        }
    };

    const handleGetNote = async () => {
        setLoadingNote(true);
        try {
            const response = await getNotesByUser(userProfile?.id, userProfile?.role);
            setNotes(response);
        } catch (error) {
            console.error('handleGetNote', error);
        }
        setLoadingNote(false);
    }

    const handleGetSessions = async () => {
        setIsLoading(true);
        try {
            const response = await getSessions(userProfile?.role, userProfile?.id);
            let sortedSessons = sortMeetings(response);
            setSessions(sortedSessons);
            dispatch(setAllSessons(response));
        } catch (error) {
            console.error('handleGetSessions', error);
        }
        setIsLoading(false);
    }

    const sortMeetings = (meetings) => {
        let sortedMeetings = [...meetings];
        sortedMeetings.sort((a, b) => new Date(a.meetingDate) - new Date(b.meetingDate));
        return sortedMeetings;
    }

    const handleDeleteSession = async (id) => {
        setIsLoading(true);
        try {
            const response = await deleteSession(id);
            if (response && response.status === 200) {
                openNotification('success',
                    'Success',
                    'Session deleted successfully'
                );
                await handleGetSessions();
            } else {
                openNotification('error',
                    'Error',
                    response?.data?.message
                );
            }
        } catch (error) {
            console.error('handleDeleteSession', error);
            openNotification('error',
                'Error',
                'Something went wrong'
            );
        }
        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    const handleJoinMeeting = async (item) => {
        window.open(
            item?.meetingUrl,
            '_blank'
        );

        if (item.meetingUrl) {
            await handleAttendance(item);
        }
    }

    const copyMeetingLink = async (item) => {
        if (item.meetingUrl) {
            await handleAttendance(item);
        }

        navigator.clipboard.writeText(
            item?.meetingUrl
        )
        messageApi.open({
            type: 'success',
            content: 'Meeting link copied to clipboard',
        });
    }

    const handleAttendance = async (item) => {
        if (userProfile?.role === 'student' || userProfile?.role === 'teacher') {
            if (!item?.participation?.id) {
                try {
                    const response = await attendToSession(item.id, userProfile?.id, item?.isRepeat);
                    if (response && response.status === 200) {
                        messageApi.open({
                            type: 'success',
                            content: 'Attendance marked successfully',
                        });
                        await handleGetSessions();
                    }
                } catch (error) {
                    console.error('handleAttendance', error);
                }
            }
        }
    }

    const renderTags = (tags) => {
        return tags?.split(',').map((tag) => {
            if (tag === 'VideoTutorials') {
                return <Tag color="blue">Video Tutorials</Tag>
            } else if (tag === 'Exams') {
                return <Tag color="blue">Exams</Tag>
            } else if (tag === 'TrainingAndCourses') {
                return <Tag color="blue">Training and Courses</Tag>
            } else if (tag === 'News') {
                return <Tag color="blue">News</Tag>
            } else if (tag === 'Seminar') {
                return <Tag color="blue">Seminar</Tag>
            }
        })
    }

    return (
        <div className='home'>
            {contextHolder}
            {contextHolderNoti}
            <div className='home-left-top'>
                <div className='bg-img'>
                </div>
                <div className='profile-img'>
                    <img
                        src={userProfile?.profileImage || DEFAULT_USER_PROFILE_IMAGE}
                        alt='profile'
                    />
                </div>
                <div className='profile-name'>
                    <h1>
                        {userProfile?.name}
                    </h1>
                    <h2>
                        {userProfile?.email}
                    </h2>
                </div>
            </div>
            <div className='home-continer'>
                <div className='home-left'>
                    <div className='updates-body'>
                        <div className='sessions-header'>
                            <h1>
                                Your Sessions Timeline
                            </h1>
                            {userProfile?.role === 'superadmin' &&
                                <Button
                                    onClick={() => setIsAddSessionOpen(true)}
                                    type='primary'
                                    className='add-btn'
                                >
                                    <PlusOutlined /> Add Session
                                </Button>}
                        </div>
                        <Spin spinning={isLoading} tip="Please wait...">
                            <>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={sessions}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={
                                                    <div className='session-header'>
                                                        {userProfile?.role === 'superadmin' &&
                                                            <div className='actions'>
                                                                <Button
                                                                    type='link'
                                                                    size='small'
                                                                    className='edit-btn'
                                                                    onClick={() => {
                                                                        setSelectedSession(item);
                                                                        setIsEditSessionOpen(true);
                                                                    }}
                                                                >
                                                                    <EditOutlined />
                                                                </Button>
                                                                <Popconfirm
                                                                    title="Are you sure to delete this session?"
                                                                    onConfirm={() => handleDeleteSession(item.id)}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        danger
                                                                        type='link'
                                                                        size='small'
                                                                        className='delete-btn'
                                                                    >
                                                                        <DeleteOutlined />
                                                                    </Button>
                                                                </Popconfirm>

                                                                {
                                                                    item?.isPublished ? <Tag color="green">Published</Tag> : <Tag color="red">Not Published</Tag>
                                                                }
                                                            </div>}

                                                    </div>
                                                }
                                                description={
                                                    <div className='meeting-data'>
                                                        <div className='left'>
                                                            {
                                                                item?.isFree && <Tag color="green">Free</Tag>
                                                            }

                                                            <h2 className='meet-title'
                                                                style={{
                                                                    width: userProfile?.role === 'superadmin' ? '70%' : '100%'
                                                                }}
                                                            >
                                                                {item?.name}
                                                            </h2>

                                                            <p className='meet-details'>
                                                                <span>Course Name: </span>
                                                                {item?.courseName}
                                                            </p>
                                                            <p>
                                                                <ReactQuill
                                                                    className='session-description'
                                                                    readOnly
                                                                    theme="bubble"
                                                                    value={item?.description}
                                                                />
                                                            </p>

                                                            <Divider />
                                                            <div className='meeting-date-time'>
                                                                {item?.meetingDate ?
                                                                    <div className='date-continer'>
                                                                        <div>
                                                                            <span>
                                                                                <CalendarOutlined />
                                                                            </span>
                                                                            <div>
                                                                                <p>{
                                                                                    item?.isRepeat ?
                                                                                        getNextWeekdayDate(item?.repeatDays)
                                                                                        :

                                                                                        item?.meetingDate || 'N/A'
                                                                                }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                <ClockCircleOutlined />
                                                                            </span>
                                                                            <p className='id'>
                                                                                {
                                                                                    new Date(`1970-01-01T${item.meetingTime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) || 'N/A'
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <p>
                                                                        Meeting date will be available soon
                                                                    </p>
                                                                }
                                                            </div>
                                                            <div className='meeting-link'>
                                                                {(
                                                                    item?.meetingUrl
                                                                ) ?
                                                                    <div className='meetlink-continer'>
                                                                        <section className='links'>
                                                                            <Button
                                                                                className='join-link'
                                                                                type='default'
                                                                                onClick={() => handleJoinMeeting(item)}
                                                                                disabled={item?.isRepeat && new Date().getDay() !== new Date(item.meetingDate).getDay()}
                                                                            >
                                                                                Join Meeting
                                                                            </Button>

                                                                            {(userProfile?.role === 'student' && !item?.isRepeat) &&
                                                                                <div className='attendance'>
                                                                                    {item.participation?.id ?
                                                                                        <span className='attended'>
                                                                                            You have attended the meeting
                                                                                        </span>
                                                                                        :
                                                                                        <span className='not-attended'>
                                                                                            You have not attended yet
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </section>
                                                                    </div>
                                                                    :
                                                                    <div className='no-meet-links'>
                                                                        <p>
                                                                            Meeting link will be available soon
                                                                        </p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className='right'>
                                                            <img src={item?.courseCoverImage} alt='course' />
                                                        </div>
                                                    </div>
                                                }

                                            />
                                        </List.Item>
                                    )}
                                />
                            </>
                        </Spin>
                    </div>
                </div>



                <div className='home-right'>
                    <Spin
                        spinning={loadingNote}
                        tip="Please wait..."
                    >
                        <div className='special-notes'>
                            <h2 className='title'>
                                Special Notes
                            </h2>

                            <div className='notes-body'>
                                <List
                                    className='notes-list'
                                    itemLayout="horizontal"
                                    dataSource={notes}
                                    renderItem={item => (
                                        <List.Item className='note-description'>
                                            <h3>
                                                <span>Course: </span>{item?.courseName}
                                            </h3>
                                            <div className='note-quill'>
                                                <ReactQuill
                                                    readOnly
                                                    theme="bubble"
                                                    value={item?.note}
                                                />
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>

            {homeCourses?.length > 0 &&
                <div className='home-courses-dashboard'>
                    <h2 className='title'>
                        Featured Courses
                    </h2>
                    <div className='courses-body'>
                        <List
                            grid={{
                                gutter: 30,
                                xs: 1,
                                sm: 1,
                                md: 1,
                                lg: 2,
                                xl: 3,
                                xxl: 4,
                            }}
                            dataSource={homeCourses}
                            renderItem={item => (
                                <List.Item>
                                    <Link to={`/dashboard/cources/${item.id}`}>
                                        <div className='course-card'>
                                            <div className='card-image-container'>
                                                <img
                                                    src={
                                                        item?.coverImage && item?.coverImage
                                                    } alt=""
                                                />
                                                <div className='cource-card-header-buttons'>
                                                    <div
                                                        className='duration'
                                                        style={{
                                                            backgroundColor: item?.isPublished ? '#4CAF50' : '#f44336'
                                                        }}
                                                    >
                                                        <p>
                                                            {
                                                                item?.isPublished ? 'Published' : 'Not Published'
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-body'>
                                                <div className='card-description'>
                                                    <div className='price'>
                                                        {
                                                            item?.price === 0
                                                                ?
                                                                <p className='free-text'>
                                                                    Free
                                                                </p>
                                                                :
                                                                <p>
                                                                    {item?.price} LKR
                                                                </p>
                                                        }
                                                    </div>

                                                    <div className='author'>
                                                        <img
                                                            src={
                                                                item?.createdBy?.profileImage
                                                                    ? item?.createdBy?.profileImage
                                                                    : DEFAULT_USER_PROFILE_IMAGE
                                                            } alt=""
                                                        />
                                                        <h3>
                                                            {item?.createdBy?.name}
                                                        </h3>
                                                    </div>
                                                </div>

                                                <h3 className='title'>
                                                    {item?.name}
                                                </h3>

                                                <div className="tags">
                                                    {renderTags(item?.tags)}
                                                </div>
                                            </div>

                                            <div className='card-footer'>
                                                <div className='button-continer'>
                                                    <Link
                                                        className='enroll-now'
                                                        to={`/dashboard/cources/${item.id}`}>
                                                        <Button
                                                            type='link'
                                                        >
                                                            View
                                                        </Button>
                                                    </Link>
                                                    <>
                                                        {
                                                            item?.whatsappGroupLink &&
                                                            <a href={item?.whatsappGroupLink} target='_blank' rel="noreferrer">
                                                                <Button type='primary' style={{
                                                                    backgroundColor: '#25d366',
                                                                }}>
                                                                    <WhatsAppOutlined />
                                                                    Join Whatsapp Group
                                                                </Button>
                                                            </a>
                                                        }
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>}

            <AddSession
                isOpen={isAddSessionOpen}
                setOpen={setIsAddSessionOpen}
                cources={cources}
                handleGetSessions={handleGetSessions}
            />

            <EditSession
                isOpen={isEditSessionOpen}
                setOpen={setIsEditSessionOpen}
                selectedSession={selectedSession}
                cources={cources}
                handleGetSessions={handleGetSessions}
            />
        </div>
    )
}

export default Home