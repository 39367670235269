import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';

import { BrowserRouter } from 'react-router-dom';


// Ant Design
import { ConfigProvider } from 'antd';
import theme from './theme/theme.json';

// Redux
import store from './store'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ConfigProvider>
);