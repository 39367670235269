import { Button, Drawer, Form, Input, Radio, Select, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import { buyACourse, getAllCoursesByRole } from '../../../../../../Constants/Api/functions/function';
import { useSelector } from 'react-redux';

const { Option } = Select;

const AddUserCourse = ({ setOpen, isOpen, handleGetUserCources, userId, userCources }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const [isAddToPayments, setIsAddToPayments] = useState(false)
    const [courses, setCourses] = useState([])
    const [newCourses, setNewCourses] = useState([])
    const allCources = useSelector(state => state.allCources.allCources);
    const userProfile = useSelector(state => state.userProfile.userProfile);

    useEffect(() => {
        if (allCources) {
            setCourses(allCources)
        } else {
            hadleGetCources();
        }
    }, [allCources, userCources, isOpen]);

    useEffect(() => {
        if (courses?.length > 0 && userCources?.length > 0) {
            const newCourses = courses.filter(course => !userCources.find(userCourse => userCourse.id === course.id));

            setNewCourses(newCourses)
        } else {
            setNewCourses(courses)
        }
    }, [courses, userCources, isOpen]);

    const hadleGetCources = async () => {
        setIsLoading(true);
        if (userProfile) {
            const response = await getAllCoursesByRole('superadmin');
            if (response) {
                setCourses(response);
            }
        }
        setIsLoading(false);
    }


    const onFinish = async (values) => {
        setIsLoading(true)

        if (values.isAddToPayments) {
            values.paymentAmount = parseInt(values.paymentAmount);
        } else {
            values.isPaid = false;
            values.isAddToPayments = false;
        }

        values.userId = userId;

        try {
            const response = await buyACourse(values);
            if (response) {
                handleGetUserCources();
                api.success({
                    message: 'User added to course'
                })
            }
        } catch (error) {
            console.error('onFinish', error);
            api.error({
                message: 'Failed to add user to course'
            })
        }
        setIsLoading(false)
        setOpen(false)
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Add Course to User"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name='courseId'
                            label='Course'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select course'
                                },
                            ]}
                        >
                            <Select
                                placeholder='Select course'
                            >
                                {newCourses?.map(course => (
                                    <Option key={course.id} value={course.id}>{course.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='isAddToPayments'
                            label='Add to payments'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select add to payments'
                                },
                            ]}
                        >
                            <Radio.Group
                                onChange={(e) => setIsAddToPayments(e.target.value)}
                            >
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {isAddToPayments && <>
                            <Form.Item
                                name='isPaid'
                                label='Is Paid'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select is paid'
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                name='paymentStatus'
                                label='Payment Status'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select payment status'
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value='pending'>Pending</Radio>
                                    <Radio value='paid'>Completed</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                name='paymentAmount'
                                label='Payment Amount'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter payment amount'
                                    },
                                ]}
                            >
                                <Input
                                    type='number'
                                    placeholder='Enter payment amount'
                                />
                            </Form.Item>

                            <Form.Item
                                name='paymentMethod'
                                label='Payment Method'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select payment method'
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='Select payment method'
                                >
                                    <Option value='cash'>Cash</Option>
                                    <Option value='card'>Card</Option>
                                    <Option value='bank'>Bank</Option>
                                </Select>
                            </Form.Item>
                        </>}

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                            >
                                Add User
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddUserCourse