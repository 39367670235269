import React, { useEffect, useRef, useState } from 'react'

import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

import { Button, Input, Space, Spin, Table } from 'antd'
import {
    SearchOutlined
} from '@ant-design/icons'
import Highlighter from 'react-highlight-words';

// import './style.scss'

import { getCouseAssestmentsByRole } from '../../../../../Constants/Api/functions/function'

const CourseAssessments = ({ courseId }) => {
    const [loading, setLoading] = useState(false)
    const [assessments, setAssessments] = useState();
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const [tableData, setTableData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);


    useEffect(() => {
        handleGetAssessments();
    }, [])

    useEffect(() => {
        if (assessments) {
            setTableData(
                assessments.map((assessment) => {
                    return {
                        id: assessment?.id,
                        key: assessment?.id,
                        title: assessment?.name,
                        endDate: assessment?.endDate,
                        status: assessment?.attempt?.id ? true : false,
                        marks: assessment?.attempt?.marks,
                    };
                })
            );
        }
    }, [assessments]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const columns = [
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/dashboard/assessments/${record.id}`}>
                        View
                    </Link>
                </Space>
            ),
        },
        {
            title: 'Assesment Title',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
            ...getColumnSearchProps('title'),
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            width: '15%',
            sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
        },
    ];

    const studentColumns = [
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/dashboard/assessments/${record.id}`}>
                        View
                    </Link>
                </Space>
            ),
        },
        {
            title: 'Assesment Title',
            dataIndex: 'title',
            key: 'title',
            width: '20%',
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Course',
            dataIndex: 'course',
            key: 'id',
            width: '20%',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            width: '15%',
            sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
            render: (endDate) => {
                const now = new Date();
                const end = new Date(endDate);
                const diff = end - now;
                const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
                let color = 'black';
                if (diffDays <= 7) {
                    color = 'blue';
                } else if (diffDays < 0) {
                    color = 'red';
                }
                return (
                    <span style={{ color: color }}>
                        {endDate}
                    </span>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '15%',
            render: (status) => {
                let color = status ? 'green' : 'red';
                return (
                    <span style={{ color: color }}>
                        {status ? 'Attempted' : 'Not Attempted'}
                    </span>
                )
            }
        },
        {
            title: 'Marks',
            dataIndex: 'marks',
            key: 'marks',
            width: '10%',
            render: (marks) => {
                let color = marks ? (marks < 50 ? 'red' : 'green') : 'black';
                return (
                    <span style={{ color: color }}>
                        {marks ? marks : 'N/A'}
                    </span>
                )
            }
        }
    ]

    const handleGetAssessments = async () => {
        setLoading(true);
        try {
            const response = await getCouseAssestmentsByRole(userProfile.role, userProfile.id, courseId);
            if (response?.length === 0) {
                setAssessments(response);
            } else {
                setAssessments(response);
            }
        } catch (error) {
            console.error('handleGetAssessments', error);
        }

        setLoading(false);
    }

    return (
        <div className='assessments'>
            <div className='assessments-header'>
                <div className='assessments-header-title'>
                    <h1>
                        Assessments
                    </h1>
                </div>
            </div>
            <div className='assessments-list'>
                <Spin spinning={loading}
                    tip='Loading...'
                >
                    <Table
                        columns={(userProfile?.role === 'superadmin') ? columns : studentColumns}
                        dataSource={tableData}
                        pagination={{
                            pageSize: 50,
                            position: ["bottomRight"],
                        }}
                        scroll={window.innerWidth <= 768 && { x: 1000 }}
                    />
                </Spin>
            </div>
        </div>
    )
}

export default CourseAssessments