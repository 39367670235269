import React, { useState } from 'react'

// Antd
import { Button, Col, Drawer, Form, Input, Row, Select, notification, Spin } from 'antd';

import { addSession } from '../../../../Constants/Api/functions/function';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';
import { useSelector } from 'react-redux';

const { Option } = Select;

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

const AddSession = ({
    isOpen,
    setOpen,
    handleGetSessions,
    cources
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const [isRepeat, setIsRepeat] = useState(false);

    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);

        if (values.isPublished === 'true') {
            values.isPublished = true;
        } else {
            values.isPublished = false;
        }

        if (values.isFree === 'true') {
            values.isFree = true;
        } else {
            values.isFree = false;
        }

        if (values.isRepeat === 'true') {
            values.isRepeat = true;
        } else {
            values.isRepeat = false;
        }


        values.createdBy = userProfile.id;

        setIsLoading(true);

        try {
            const res = await addSession(values);
            if (res && res.status === 200) {
                openNotification('success',
                    'Success',
                    'New session added successfully'
                );
                setOpen(false);
                await handleGetSessions();
            } else {
                openNotification('error',
                    'Error',
                    res.data.message
                );
            }
        } catch (error) {
            console.error('AddCource', error);
            openNotification('error',
                'Error',
                'Something went wrong'
            );
        }

        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Scedule New Session"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Select Course"
                            name="courseId"
                            rules={[{ required: true, message: 'Please select course!' }]}>
                            <Select
                                placeholder="Please select course"
                            >
                                {cources?.map((cource, index) => (
                                    <Option
                                        key={index}
                                        value={cource.id}
                                    >
                                        {cource.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Session Name (Max: 200)"
                            name="name"
                            rules={[{ required: true, message: 'Please input the English name!' }]}
                        >
                            <Input
                                showCount
                                maxLength={200}
                                placeholder="Name English" />
                        </Form.Item>
                        <Form.Item
                            label="Session Description (Content Max: 1000)"
                            name="description"
                        >
                            <ReactQuill
                                modules={modules}
                                theme="snow"
                                placeholder="The content starts here..."
                                style={{
                                    height: '100%'
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Meeting URL"
                            name="meetingUrl"
                        >
                            <Input placeholder="Meeting URL" />
                        </Form.Item>
                        <Form.Item
                            label="Meeting ID"
                            name="meetingId"
                        >
                            <Input placeholder="Meeting ID" />
                        </Form.Item>
                        <Form.Item
                            label="Meeting Passcode"
                            name="meetingPassword"
                        >
                            <Input placeholder="Meeting Passcode" />
                        </Form.Item>
                        <Form.Item
                            label="Meeting Date"
                            name="meetingDate"
                        >
                            <Input placeholder="Meeting Date" type='date' />
                        </Form.Item>
                        <Form.Item
                            label="Meeting Time"
                            name="meetingTime"
                        >
                            <Input placeholder="Meeting Time" type='time' />
                        </Form.Item>

                        <Form.Item
                            name="isRepeat"
                            label="Is Repeat"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select is repeat",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select is repeat"
                                onChange={(value) => setIsRepeat(value === 'true' ? true : false)}
                            >
                                <Option value="true">
                                    Repeat
                                </Option>
                                <Option value="false">
                                    Not Repeat
                                </Option>
                            </Select>
                        </Form.Item>

                        {isRepeat &&
                            <Form.Item
                                name="repeatDays"
                                label="Repeat Days"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select repeat days",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Please select repeat days"
                                >
                                    <Option value="monday">
                                        Monday
                                    </Option>
                                    <Option value="tuesday">
                                        Tuesday
                                    </Option>
                                    <Option value="wednesday">
                                        Wednesday
                                    </Option>
                                    <Option value="thursday">
                                        Thursday
                                    </Option>
                                    <Option value="friday">
                                        Friday
                                    </Option>
                                    <Option value="saturday">
                                        Saturday
                                    </Option>
                                    <Option value="sunday">
                                        Sunday
                                    </Option>
                                </Select>
                            </Form.Item>
                        }

                        <Form.Item
                            name="isFree"
                            label="Is Free"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select is free",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select is free"
                            >
                                <Option value="true">
                                    Free
                                </Option>
                                <Option value="false">
                                    Not Free
                                </Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="isPublished"
                            label="Is Published"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select is published",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select is published"
                            >
                                <Option value="true">
                                    Published
                                </Option>
                                <Option value="false">
                                    Not Published
                                </Option>
                            </Select>
                        </Form.Item>

                        <Row gutter={16}>
                            <Col>
                                <Button
                                    style={{ width: '100%' }}
                                    type="primary" htmlType="submit">
                                    Add Session
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default AddSession