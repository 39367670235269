import { createSlice } from "@reduxjs/toolkit";

const allSessonsReducer = createSlice({
    name: "allSessons",
    initialState: {
        allSessons: null,
        isLoading: false,
        error: null
    },
    reducers: {
        setAllSessons: (state, action) => {
            state.allSessons = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setAllSessons, setIsLoading, setError } = allSessonsReducer.actions

export default allSessonsReducer.reducer;