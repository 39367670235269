import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Collapse, Progress, Spin } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import './style.scss';
import { getProgressByUserId } from '../../../../../Constants/Api/functions/function';

const UserProgress = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const language = localStorage.getItem('language');
    const [items, setItems] = useState([]);

    useEffect(() => {
        const handleGetUserProgress = async () => {
            setIsLoading(true);
            try {
                const response = await getProgressByUserId(id);
                setItems(getItems(response));
            } catch (error) {
                console.error('handleGetUserProgress', error);
            } finally {
                setIsLoading(false);
            }
        };

        handleGetUserProgress();
    }, [id]);

    const calculatePercentage = (content) => {
        const total = content.length;
        const completed = content.filter((item) => item?.participation?.id).length;
        return parseFloat(((completed / total) * 100).toFixed(2));
    };

    const renderLabel = (item) => (
        <div className='progress-course__label'>
            <span className='title'>
                {item?.name}
            </span>
            <div className='progress'>
                <Progress
                    percent={calculatePercentage(item.content)}
                    status='active'
                    strokeColor={{ from: '#673bf9', to: '#B100C6' }}
                />
            </div>
        </div>
    );

    const renderBody = useMemo(() => (content) => (
        <div className='progress-course__body'>
            <ul>
                {content.map((item, index) => (
                    <li key={index.toString()}>
                        <div className='content'>
                            <p>
                                {item?.title}
                            </p>
                            <div className='is-completed'>
                                {item?.participation?.id ? (
                                    <span className='completed'>
                                        <CheckRoundedIcon />
                                    </span>
                                ) : (
                                    <span className='not'>
                                        <CheckRoundedIcon />
                                    </span>
                                )}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    ), [language]);

    const getItems = useMemo(() => (progressData) => {
        if (progressData) {
            return progressData.map((item, index) => ({
                key: index.toString(),
                label: renderLabel(item),
                children: renderBody(item.content),
                style: { background: '#f7f7f7' },
            }));
        }
        return [];
    }, [renderLabel, renderBody]);

    return (
        <div className='user-progress'>
            <Spin spinning={isLoading} size='large' tip='Loading...'>
                <div className='progress-courses'>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        items={items}
                    />
                </div>
            </Spin>
        </div>
    );
};

export default UserProgress;