import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { setUserProfile } from '../../Reducers/userProfileReducer';

// Components
import SideBar from './Common/SideBar';
import DashboardHeader from './Common/DashboardHeader';
import DashboardContent from './Common/DashboardContent';
import { Footer } from 'antd/es/layout/layout';

import './index.scss';
import { getUserById } from '../../Constants/Api/functions/function';

const Dashboard = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [userData, setUserData] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('digital-academy-user'))
        if (user) {
            hnadlegetUserById(user)
        } else {
            window.location.href = '/login'
        }
    }, [])

    const hnadlegetUserById = async (user) => {
        try {
            const response = await getUserById(user.id)
            if (response?.id) {
                setUserData(response)
                dispatch(setUserProfile(response))
                localStorage.setItem('digital-academy-user', JSON.stringify(response))
            }
        } catch (error) {
            console.log('handlegetUserById -> error', error)
        }
    }

    return (
        <Layout className='dashboard'>
            {
                userData ?
                    <>
                        <SideBar
                            userData={userData}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                        <Layout
                            onClick={() => {
                                if (window.innerWidth < 768) {
                                    if (!collapsed) {
                                        setCollapsed(true);
                                    }
                                }
                            }}
                        >
                            <div
                                className={!collapsed ? 'dark-overlay' : 'dark-overlay-active'}
                            ></div>
                            <DashboardHeader />
                            <DashboardContent />
                            <Footer className='footer-dashboard'>
                                <p>
                                    © 2024
                                    <a
                                        href='https://www.digitalacademy.lk/'
                                        target='_blank'
                                        rel='noreferrer'
                                    > Digital Academy</a> All RIghts Reserved.
                                </p>

                                <p>
                                    Designed & Developed by
                                    <a
                                        href='https://www.isuruariyarathna.me/'
                                        target='_blank'
                                        rel='noreferrer'
                                    > Isuru Ariyarathna</a>
                                </p>
                            </Footer>
                        </Layout>
                    </>
                    :
                    <div className='laoder-anim'>
                        <div class="three-body">
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                        </div>
                        <p>
                            Please wait
                        </p>
                    </div>
            }

        </Layout>
    )
}

export default Dashboard