import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCourseById } from '../../../../../Constants/Api/functions/function';
import { Button, Spin } from 'antd';

import './styles.scss'

const BuyCourse = () => {
    const allCources = useSelector(state => state.allCources.allCources);
    const [course, setCourse] = useState()
    const courseId = useParams().id
    const [isLoading, setIsLoading] = useState(false)
    const userProfile = useSelector(state => state.userProfile.userProfile);

    useEffect(() => {
        if (allCources) {
            setCourse(allCources.find(item => item.id === courseId));
        } else {
            handleGetCourse();
        }
    }, [allCources]);

    const handleGetCourse = async () => {
        setIsLoading(true);
        try {
            const response = await getCourseById(courseId);
            if (response) {
                setCourse(response[0]);
            }
        } catch (error) {
            console.error('handleGetCourse', error);
        }
        setIsLoading(false);
    }

    return (
        <div className='buy-course'>
            <h1 className='buy-title'>Buy Course</h1>
            <Spin spinning={isLoading}>
                <div className='course-data'>
                    <h2>Course Name: {course?.name}</h2>
                    <p>{course?.description}</p>
                    <h3>{course?.price} LKR</h3>
                    <p>User Name: {userProfile?.name}</p>
                </div>
            </Spin>

            <div className='instruction'>
                <h2>Instruction</h2>
                <p>Please follow the following steps to buy the course.</p>

                <p>
                    You can make a bank transfer to the following account number. Please make sure to include your name and the course name in the transaction description.
                    <br />

                    <section>
                        <span>Account Number:</span> 106314013195
                        <br />
                        <span>Bank:</span> Sampath Bank
                        <br />
                        <span>Branch:</span> Palawaththa SPU
                        <br />
                        <span>Name:</span> Netlink System (pvt) ltd
                    </section>
                </p>

                <p>
                    After making the payment, please send the transaction receipt to the following email address.
                    <br />

                    <section>Email: <span>Payment@digitalacademy.lk</span></section>
                    <Button
                        type='link'
                        style={{
                            padding: 0,
                            marginBottom: '10px'
                        }}
                        href='mailto:Payment@digitalacademy.lk'
                    >
                        Send Email
                    </Button>
                    <br />

                    or you can also send the transaction receipt to the following phone number via WhatsApp.

                    <br />

                    <section>Phone: <span>+94774747920</span></section>
                    <Button
                        type='link'
                        style={{
                            padding: 0,
                            marginBottom: '10px'
                        }}
                        href={`https://wa.me/+94774747920?text=Hello%2C%20I%20am%20${userProfile?.name}%20(Index%20No:%20${userProfile?.indexNumber}).%20I%20have%20made%20the%20payment%20for%20the%20course%20${course?.name}%20and%20here%20is%20the%20transaction%20receipt.`}
                        target='_blank'
                    >
                        Send WhatsApp
                    </Button>
                </p>

                <p>
                    After verifying the payment, you will be enrolled in the course and you will receive a confirmation email.
                </p>
            </div>
        </div>
    )
}

export default BuyCourse