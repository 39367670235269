import React, { useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    Spin,
} from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

// API
import { getUserById, signInUser } from '../../Constants/Api/functions/function';

// Redux
import { useDispatch } from 'react-redux';
import { setUserProfile } from "../../Reducers/userProfileReducer";

// Assets
import signinBg from '../../Assets/Images/signinBg.png'
import signin from '../../Assets/Images/signin.png'

import './style.scss'


const Login = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('digital-academy-user'))
        if (user) {
            successModal()
            handleGetUser(user.id)
        }
    }, [])

    const onFinish = async (values) => {
        await handleUserLogin(values)
    };

    const handleGetUser = async (id) => {
        setIsLoading(true)
        const response = await getUserById(id)
        dispatch(setUserProfile(response))
        window.location.href = '/dashboard'
        setIsLoading(false)
    }

    const handleUserLogin = async (user) => {
        setIsLoading(true)
        const response = await signInUser(user)
        setIsLoading(false)

        if (response.status === 200) {
            successModal()

            dispatch(setUserProfile(response.data))

            localStorage.setItem('digital-academy-user', JSON.stringify(response.data))

            setTimeout(() => {
                window.location.href = '/dashboard'
            }, 1000);

        } else if (response.status === 404) {
            userNotFoundModal()
        } else if (response.status === 400) {
            incrrectPasswordModal()
        } else {
            errorModal()
        }
    }

    const successModal = () => {
        Modal.success({
            content: 'You have successfully signed in. You will be redirected to the dashboard in a few seconds.',
            className: 'register-modal',
        });
    }

    const errorModal = () => {
        Modal.error({
            title: 'Error',
            content: 'An error occurred while signing in. Please try again.',
            className: 'register-modal',
        });
    }

    const userNotFoundModal = () => {
        Modal.error({
            title: 'User not found',
            content: 'Please check the email address and try again.',
            className: 'register-modal',
        });
    }

    const incrrectPasswordModal = () => {
        Modal.error({
            title: 'Incorrect Password',
            content: 'Please check the password and try again.',
            className: 'register-modal',
        });
    }

    return (
        <div className='signin-page'
            style={{
                backgroundImage: `url(${signinBg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            <section className="form">
                <div className="signin-top"
                    style={{
                        backgroundImage: `url(${signin})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}
                >
                    <h1>
                        Welcome to Digital Academy
                    </h1>
                    <h2>
                        Sign in to continue
                    </h2>
                </div>
                <Spin spinning={isLoading}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            className='form-item'
                            label="Email Address"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Email address!',
                                },
                                {
                                    pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                    message: 'The input is not valid E-mail!',
                                }
                            ]}
                        >
                            <Input placeholder="sampleuser@gmail.com" />
                        </Form.Item>

                        <Form.Item
                            className='form-item'
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your password',
                                },
                            ]}
                        >
                            <Input.Password
                                placeholder="Input password"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>

                        <a
                            className='fogot-button'
                            type="link"
                            onClick={() => window.location.href = '/forgot-password'}
                            href="/forgot-password"
                        >
                            Fogot Password?
                        </a>

                        <Button
                            className='form-button'
                            type="primary"
                            htmlType="submit"
                        >
                            Log in
                        </Button>

                        <div className="sign-up">
                            <p>Don't have an account? &nbsp;
                                <a href="/register">Sign up</a></p>
                        </div>
                    </Form>
                </Spin>
            </section>
        </div>
    )
}
export default Login