import React, { useEffect, useState } from 'react';

// Antd
import {
    WhatsAppOutlined,
} from '@ant-design/icons';
import {
    AutoComplete,
    Button,
    Input,
    List,
    Select,
    Spin,
    Tag
} from 'antd';
import { Link } from 'react-router-dom';

// API
import { getUsersCourses } from '../../../Constants/Api/functions/function';

// Redux
import { useSelector } from 'react-redux';

// Styles
import './style.scss';

import { DEFAULT_USER_PROFILE_IMAGE } from '../../../Constants/Api/azure/azure';

const MyCources = () => {
    const [isLoading, setIsLoading] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const [userCources, setUserCources] = useState();
    const [data, setData] = useState();
    const [allCources, setAllCources] = useState();
    const [coursesData, setCoursesData] = useState()

    useEffect(() => {
        if (data) {
            const sortedData = [...data].sort((a, b) => {
                return a?.orderNumber - b?.orderNumber;
            });

            setCoursesData(
                sortedData.map((item) => {
                    return {
                        id: item?.id,
                        title: item?.name,
                        discription: item?.description,
                        isPublished: item?.isPublished,
                        thumbnail: item?.coverImage,
                        price: item?.price,
                        authorName: item?.createdBy?.name,
                        authorImage: item?.createdBy?.profileImage,
                        orderNumber: item?.orderNumber,
                        whatsappGroupLink: item?.whatsappGroupLink,
                        tags: item?.tags,
                    };
                })
            );
        }
    }, [data]);

    useEffect(() => {
        if (userProfile?.role !== 'superadmin') {
            handleGetUserCources();
        }
    }, []);

    const handleGetUserCources = async () => {
        setIsLoading(true)
        try {
            const userCources = await getUsersCourses(userProfile?.id)
            setData(userCources)
            setUserCources(userCources)
            setAllCources(userCources)
        } catch (error) {
            console.error('handleGetUserCources', error)
        }
        setIsLoading(false)
    }

    const filterBytags = (value) => {
        if (value === 'All') {
            setData(allCources);
        } else {
            const filteredData = data?.filter((item) => {
                const tagsArray = item?.tags?.split(',');
                return tagsArray?.find((tag) => tag === value);
            });

            setData(filteredData);
        }
    }

    const renderTags = (tags) => {
        return tags?.split(',').map((tag) => {
            if (tag === 'VideoTutorials') {
                return <Tag color="blue">Video Tutorials</Tag>
            } else if (tag === 'Exams') {
                return <Tag color="blue">Exams</Tag>
            } else if (tag === 'TrainingAndCourses') {
                return <Tag color="blue">Training and Courses</Tag>
            } else if (tag === 'News') {
                return <Tag color="blue">News</Tag>
            } else if (tag === 'Seminar') {
                return <Tag color="blue">Seminar</Tag>
            }
        })
    }

    return <div className='cources'>
        <div className='cources-action-buttons'>
            <div className='filters'>
                <div className='filter-search'>
                    <h2>
                        All Courses
                    </h2>
                    <div className='filter-inputs'>
                        <AutoComplete
                            popupMatchSelectWidth={252}
                            options={allCources?.map((item) => {
                                return {
                                    value: item.name,
                                    label: item.name,
                                }
                            })}
                            size="large"
                            onSelect={(value) => {
                                if (value) {
                                    const filteredData = allCources?.filter((item) => {
                                        return item?.name?.toLowerCase().includes(value.toLowerCase());
                                    });
                                    setData(filteredData);
                                } else {
                                    setData(allCources);
                                }
                            }}
                        >
                            <Input.Search size="large"
                                placeholder="Search by title"
                                enterButton
                                onSearch={(value) => {
                                    if (value) {
                                        const filteredData = allCources?.filter((item) => {
                                            return item?.name?.toLowerCase().includes(value.toLowerCase());
                                        });
                                        setData(filteredData);
                                    } else {
                                        setData(allCources);
                                    }
                                }}
                                onChange={(e) => {
                                    if (!e.target.value) {
                                        setData(allCources);
                                    } else {
                                        const filteredData = allCources?.filter((item) => {
                                            return item?.name?.toLowerCase().includes(e.target.value.toLowerCase());
                                        });
                                        setData(filteredData);
                                    }
                                }}
                            />
                        </AutoComplete>
                    </div>
                </div>

                <div className='radio-buttons'>
                    <Select placeholder="Filter by tags"
                        style={{
                            minWidth: '150px'
                        }}
                        onChange={(value) => filterBytags(value)}
                    >
                        <Select.Option value={'All'}>All</Select.Option>
                        <Select.Option value={'VideoTutorials'}>Video Tutorials</Select.Option>
                        <Select.Option value={'Exams'}>Exams</Select.Option>
                        <Select.Option value={'TrainingAndCourses'}>Training and Courses</Select.Option>
                        <Select.Option value={'News'}>News</Select.Option>
                        <Select.Option value={'Seminar'}>Seminar</Select.Option>
                    </Select>
                </div>
            </div>
        </div>
        <Spin
            spinning={isLoading}
            tip="Loading Courses..."
        >
            <div className='cources-body'>
                <Spin
                    tip="Loading..."
                    spinning={isLoading}
                >
                    <List
                        grid={{
                            gutter: 30,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 2,
                            xl: 3,
                            xxl: 4,
                        }}
                        dataSource={coursesData}
                        renderItem={(item) => (
                            <List.Item>
                                <Link to={`/dashboard/cources/${item.id}`}>
                                    <div className='course-card'>
                                        <div className='card-image-container'>
                                            <img
                                                src={
                                                    item?.thumbnail
                                                        ? item?.thumbnail
                                                        : require('./1695793881259.jpeg')
                                                } alt=""
                                            />
                                            <div className='cource-card-header-buttons'>
                                                <div
                                                    className='duration'
                                                    style={{
                                                        backgroundColor: item?.isPublished ? '#4CAF50' : '#f44336'
                                                    }}
                                                >
                                                    <p>
                                                        {
                                                            item?.isPublished ? 'Published' : 'Not Published'
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <div className='card-description'>
                                                <div className='price'>
                                                    {
                                                        item?.price === 0
                                                            ?
                                                            <p className='free-text'>
                                                                Free
                                                            </p>
                                                            :
                                                            <p>
                                                                {item?.price} LKR
                                                            </p>
                                                    }
                                                </div>

                                                <div className='author'>
                                                    <img
                                                        src={
                                                            item?.authorImage
                                                                ? item?.authorImage
                                                                : DEFAULT_USER_PROFILE_IMAGE
                                                        } alt=""
                                                    />
                                                    <h3>
                                                        {item?.authorName}
                                                    </h3>
                                                </div>
                                            </div>

                                            <h3 className='title'>
                                                {item?.title}
                                            </h3>

                                            <div className="tags">
                                                {renderTags(item?.tags)}
                                            </div>
                                        </div>

                                        <div className='card-footer'>
                                            <div className='button-continer'>
                                                <>
                                                    {userProfile?.role !== 'superadmin' ?
                                                        <Link
                                                            className='enroll-now'
                                                            to={`/dashboard/cources/${item.id}`}><Button
                                                                type='link'
                                                            >
                                                                {
                                                                    userCources?.find((course) => course?.id === item?.id)
                                                                        ?
                                                                        'Continue'
                                                                        :
                                                                        'Enroll Now'
                                                                }
                                                            </Button>
                                                        </Link>
                                                        :
                                                        <Link
                                                            className='enroll-now'
                                                            to={`/dashboard/cources/${item.id}`}>
                                                            <Button
                                                                type='link'
                                                            >
                                                                View
                                                            </Button>
                                                        </Link>
                                                    }
                                                </>
                                                <>
                                                    {
                                                        item?.whatsappGroupLink &&
                                                        <a href={item?.whatsappGroupLink} target='_blank' rel="noreferrer">
                                                            <Button type='primary' style={{
                                                                backgroundColor: '#25d366',
                                                            }}>
                                                                <WhatsAppOutlined />
                                                                Join Whatsapp Group
                                                            </Button>
                                                        </a>
                                                    }
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </List.Item>
                        )}
                    />
                </Spin>
            </div>
        </Spin>
    </div>;
};
export default MyCources;