import { Avatar, Button, Divider, Drawer, Form, Input, List, Radio, Select, Spin, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { DEFAULT_USER_PROFILE_IMAGE } from '../../../../../Constants/Api/azure/azure';
import {
    DeleteOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { buyACourse, getAllUsers, removeAUserFromACourse } from '../../../../../Constants/Api/functions/function';
import { setAllUsers } from '../../../../../Reducers/allUsersReducer';

const { Option } = Select;

const CourseUsers = ({
    openCourseUsers,
    setOpenCourseUsers,
    getCourseUsers,
    courseId,
    courseUsers,
    course
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const allUsers = useSelector(state => state.allUsers.allUsers);
    const [newUsers, setNewUsers] = useState([])
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const dispatch = useDispatch();
    const [isAddToPayments, setIsAddToPayments] = useState(false)
    const fromRef = useRef();

    useEffect(() => {
        if (allUsers.length < 1) {
            handleGetAllUsers();
        } else {
            setUsers(allUsers)
        }
    }, [allUsers]);

    useEffect(() => {
        if (course && fromRef.current) {
            fromRef.current.setFieldsValue({
                paymentAmount: course.price.toString()
            })
        }
    }, [course, fromRef.current])

    useEffect(() => {
        if (users?.length > 0 && courseUsers?.length > 0) {
            const newUsers = users.filter(user => !courseUsers.find(courseUser => courseUser.id === user.id));

            setNewUsers(newUsers)
        } else {
            setNewUsers(users)
        }
    }, [users, courseUsers]);

    const handleGetAllUsers = async () => {
        setIsLoading(true)
        try {
            if (userProfile?.role === 'superadmin') {
                const response = await getAllUsers();
                if (response) {
                    dispatch(setAllUsers(response))
                    setUsers(response)
                }
            }
        } catch (error) {
            console.error('getAllUsers', error);
        }
        setIsLoading(false)
    }

    const onFinish = async (values) => {
        setIsLoading(true)
        values.courseId = courseId;

        if (values.isAddToPayments) {
            values.paymentAmount = parseInt(values.paymentAmount);
        } else {
            values.isPaid = false;
            values.isAddToPayments = false;
        }

        try {
            const response = await buyACourse(values);
            if (response) {
                getCourseUsers();
                api.success({
                    message: 'User added to course'
                })
            }
        } catch (error) {
            console.error('onFinish', error);
            api.error({
                message: 'Failed to add user to course'
            })
        }
        setIsLoading(false)
    }

    const handleDeleteUser = async (userId) => {
        setIsLoading(true)
        try {
            const response = await removeAUserFromACourse(userId, courseId);
            if (response) {
                getCourseUsers();
                api.success({
                    message: 'User removed from course'
                })
            }
        } catch (error) {
            console.error('handleDeleteUser', error);
            api.error({
                message: 'Failed to remove user from course'
            })
        }
        setIsLoading(false)
    }

    return (
        <Drawer
            title="Course Users"
            width={720}
            onClose={() => setOpenCourseUsers(false)}
            open={openCourseUsers}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
        >
            {contextHolder}
            <Spin
                spinning={isLoading}
                tip="Please wait..."
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    ref={fromRef}
                >
                    <Form.Item
                        name='userId'
                        label='User'
                        rules={[
                            {
                                required: true,
                                message: 'Please select user'
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder='Select user'
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {newUsers.map(user => (
                                <Option key={user.id} value={user.id}>
                                    {`${user?.indexNumber} - ${user.name}`}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='isAddToPayments'
                        label='Add to payments'
                        rules={[
                            {
                                required: true,
                                message: 'Please select add to payments'
                            },
                        ]}
                    >
                        <Radio.Group
                            onChange={(e) => setIsAddToPayments(e.target.value)}
                        >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {isAddToPayments && <>
                        <Form.Item
                            name='isPaid'
                            label='Is Paid'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select is paid'
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name='paymentStatus'
                            label='Payment Status'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select payment status'
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value='pending'>Pending</Radio>
                                <Radio value='paid'>Completed</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name='paymentAmount'
                            label='Payment Amount'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter payment amount'
                                },
                            ]}
                        >
                            <Input
                                type='number'
                                placeholder='Enter payment amount'
                            />
                        </Form.Item>

                        <Form.Item
                            name='paymentMethod'
                            label='Payment Method'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select payment method'
                                },
                            ]}
                        >
                            <Select
                                placeholder='Select payment method'
                            >
                                <Option value='cash'>Cash</Option>
                                <Option value='card'>Card</Option>
                                <Option value='bank'>Bank</Option>
                            </Select>
                        </Form.Item>
                    </>}

                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                        >
                            Add User
                        </Button>
                    </Form.Item>
                </Form>

                <Divider />

                <List
                    itemLayout="horizontal"
                    dataSource={courseUsers}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src={item.profileImage || DEFAULT_USER_PROFILE_IMAGE} />}
                                title={item.name}
                                description={item.email}
                            />
                            <Button
                                type='link'
                                danger
                                onClick={() => handleDeleteUser(item.id)}
                            >
                                <DeleteOutlined />
                            </Button>
                        </List.Item>
                    )}
                />
            </Spin>
        </Drawer>
    )
}

export default CourseUsers