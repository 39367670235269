import React, { useEffect, useRef, useState } from 'react';

// Antd
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
    DeleteOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import { Button, Input, Popconfirm, Space, Spin, Table } from 'antd';

import { Link } from 'react-router-dom';
import { deletePayment } from '../../../../Constants/Api/functions/function';

const PaymentsTable = ({ isLoading, paymentData, cources, users, setPayments }) => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [leoadingDeleteId, setLoadingDeleteId] = useState(null);
    const searchInput = useRef(null);

    useEffect(() => {
        let data = paymentData.map((payment) => {
            const user = users?.find((user) => user.id === payment.userId);
            const course = cources?.find((course) => course.id === payment.courseId);
            const date = new Date(payment.createdAt).toLocaleDateString();
            return {
                key: payment.id,
                name: user?.name,
                userId: payment?.userId,
                paymentId: payment.id,
                course: course?.name,
                amount: payment.amount,
                date: date,
                method: payment.paymentMethod
            }
        }
        )
        setTableData(data);
    }, [paymentData, cources, users]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleDeletePayment = async (record) => {
        setLoadingDeleteId(record.paymentId);
        try {
            await deletePayment(record.paymentId);
            const newPayments = paymentData.filter((payment) => payment.id !== record.paymentId);
            setPayments(newPayments);
        } catch (error) {
            console.error('handleDeletePayment', error);
        }
        setLoadingDeleteId(null);
    };

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/users/${record.userId}/overview`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/users/${record.userId}/overview`}>
                    <p
                        style={{
                            color: '#1677ff',
                            cursor: 'pointer',
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            ...getNameSearchProps('name'),
        },
        {
            title: 'Payment ID',
            dataIndex: 'paymentId',
            key: 'paymentId',
            width: '30%',
            ...getColumnSearchProps('paymentId'),
        },
        {
            title: 'Course',
            dataIndex: 'course',
            key: 'course',
            width: '15%',
            ...getColumnSearchProps('course'),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: '10%',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '15%',
        },
        {
            title: 'Method',
            dataIndex: 'method',
            key: 'method',
            width: '10%',
        },
        {
            title: 'Delete Payment',
            key: 'action',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure you want to delete this payment?"
                    onConfirm={() => handleDeletePayment(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="link" danger>
                        {leoadingDeleteId === record.paymentId ? <LoadingOutlined /> : <DeleteOutlined />}
                        Delete
                    </Button>
                </Popconfirm>
            ),
        }
    ];

    return (
        <div className='table'>
            <Spin
                spinning={isLoading}
                tip="Loading payments..."
            >
                <Table
                    columns={columns}
                    dataSource={tableData}
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: false,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} records`,
                    }}
                />
            </Spin>
        </div>
    )
}

export default PaymentsTable