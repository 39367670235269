import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setAllUsers } from '../../../../../Reducers/allUsersReducer';

import { deleteAssessmentSubmission, getAllUsers, getSubmissionsByAssestment } from '../../../../../Constants/Api/functions/function';

import { Button, Space, Input, Table, Tooltip, Popconfirm, notification } from 'antd';
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
    DeleteOutlined
} from '@ant-design/icons';


import './style.scss'
import ReviewSubmission from './ReviewSubmission';
import UpdateReviewSubmission from './UpdateReviewSubmission';

const Submission = ({ assessmentId }) => {
    const [loading, setLoading] = useState(false)
    const [submissions, setSubmissions] = useState([]);
    const [selectedSubmission, setSelectedSubmission] = useState(null);
    const [isOpenReview, setIsOpenReview] = useState(false);
    const [isOpenUpdateReview, setIsOpenUpdateReview] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allUsers = useSelector(state => state.allUsers.allUsers);
    const [students, setStudents] = useState([])
    const [tableData, setTableData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        handleGetSubmissions();
    }, [assessmentId])

    useEffect(() => {
        if (allUsers?.length === 0) {
            handleGetAllUsers()
        } else {
            setStudents(allUsers.filter(user => user.role === 'student'))
        }
    }, [allUsers])

    const handleGetAllUsers = async () => {
        setLoading(true)
        try {
            const response = await getAllUsers();
            if (response) {
                dispatch(setAllUsers(response))
            }
        } catch (error) {
            console.error('getAllUsers', error);
        }
        setLoading(false)
    }

    useEffect(() => {
        if (submissions.length) {
            setTableData(
                submissions.map((submission) => {
                    return {
                        id: submission?.id,
                        key: submission?.id,
                        student: allUsers.find(user => user.id === submission?.userId)?.name,
                        isReviewed: submission?.isReviewed,
                        marks: submission?.marks ? submission?.marks : 'N/A',
                        submissionUrl: submission?.submissionUrl,
                        reviewedBy: submission?.reviewedBy,
                    }
                })
            )
        }
    }, [submissions])

    const handleGetSubmissions = async () => {
        setLoading(true);
        try {
            const response = await getSubmissionsByAssestment(assessmentId);
            setSubmissions(response);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const columns = [
        {
            title: 'Student',
            dataIndex: 'student',
            key: 'student',
            width: '20%',
            ...getColumnSearchProps('student'),
        },
        {
            title: 'Is Reviewed',
            dataIndex: 'isReviewed',
            key: 'isReviewed',
            width: '15%',
            render: (text, record) => {
                const reviewedBy = allUsers.find(user => user.id === record.reviewedBy)?.name;
                return (
                    record.isReviewed
                        ?
                        <Tooltip title={`Reviewed by ${reviewedBy}`}>
                            <p
                                style={{
                                    color: 'green',
                                    fontWeight: 'bold',
                                    cursor: 'pointer'
                                }}
                            >Yes</p>
                        </Tooltip>
                        :
                        <p>No</p>
                )
            }
        },
        {
            title: 'Marks',
            dataIndex: 'marks',
            key: 'marks',
            width: '15%',
            sorter: (a, b) => a.marks - b.marks,
        },
        {
            title: 'Action',
            key: 'action',
            width: '40%',
            render: (text, record) => (
                <div className='actions'>
                    <Button
                        type='link'
                    >
                        <a href={record?.submissionUrl} target="_blank" rel="noreferrer">
                            View Submission
                        </a>
                    </Button>
                    {userProfile?.role === 'superadmin' && <>
                        {
                            record.isReviewed
                                ?
                                <Button
                                    type='primary'
                                    onClick={() => {
                                        setSelectedSubmission(submissions.find(submission => submission.id === record.id));
                                        setIsOpenUpdateReview(true);
                                    }}
                                >
                                    Update
                                </Button>
                                : <Button
                                    type='primary'
                                    onClick={() => {
                                        setSelectedSubmission(submissions.find(submission => submission.id === record.id));
                                        setIsOpenReview(true);
                                    }}
                                >
                                    Review
                                </Button>
                        }

                        <Popconfirm
                            title="Are you sure to delete this submission?"
                            onConfirm={() => handleDeleteSubmission(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                danger
                                type='link'
                            >
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </>}
                </div>
            ),
        }
    ];

    const handleDeleteSubmission = async (submissionId) => {
        setLoading(true);
        try {
            const response = await deleteAssessmentSubmission(submissionId);
            if (response && response?.status === 200) {
                await handleGetSubmissions();
                openNotification('success', 'Submission Deleted', 'The submission has been deleted successfully.');
            } else {
                openNotification('error', 'Error', 'An error occurred while deleting the submission.');
            }
        } catch (error) {
            console.error('deleteSubmission', error);
            openNotification('error', 'Error', 'An error occurred while deleting the submission.');
        }
        setLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div className='submissions'>
            {contextHolder}
            <div className='submissions__header'>
                <h2>Submissions</h2>
            </div>
            <div className='submissions__body'>
                <p>
                    {`${submissions?.length} out of ${students?.length} students have submitted their assessments.`}
                </p>

                <div className='submissions__table'>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        loading={loading}
                        pagination={{
                            pageSize: 20,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '100'],
                        }}
                    />
                </div>
            </div>

            <ReviewSubmission
                isOpen={isOpenReview}
                setOpen={setIsOpenReview}
                handleGetSubmissions={handleGetSubmissions}
                submissionData={selectedSubmission}
                userData={allUsers.find(user => user?.id === selectedSubmission?.userId)}
            />

            <UpdateReviewSubmission
                isOpen={isOpenUpdateReview}
                setOpen={setIsOpenUpdateReview}
                handleGetSubmissions={handleGetSubmissions}
                submissionData={selectedSubmission}
                userData={allUsers.find(user => user?.id === selectedSubmission?.userId)}
            />
        </div>
    )
}

export default Submission