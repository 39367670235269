import React, { useEffect, useState } from 'react'

import {
  Button,
  Carousel,
  List,
  Spin,
} from 'antd'
import {
  EnvironmentTwoTone,
  PhoneTwoTone,
  MailTwoTone,
  WhatsAppOutlined,
} from '@ant-design/icons';

// Reveal
import AOS from 'aos';
import 'aos/dist/aos.css';

import './styles.scss'
import './button.scss'

import logo from '../../Assets/Logos/logo.png'
import history from '../../../history';

import heroImage from './Assets/herobg.png'
import { getHomeCourses } from '../../Constants/Api/functions/function';
import { DEFAULT_COURSE_COVER_IMAGE, DEFAULT_USER_PROFILE_IMAGE } from '../../Constants/Api/azure/azure';
import { Link } from 'react-router-dom';

import image1 from './Assets/c1.jpg'
import image2 from './Assets/c2.jpg'
import image3 from './Assets/c3.jpg'
import image4 from './Assets/c4.jpg'

AOS.init();
AOS.init({
  disable: false,
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,

  offset: 120,
  delay: 0,
  duration: 500,
  easing: 'ease',
  once: false,
  mirror: false,
  anchorPlacement: 'top-bottom',
});

const specifications = [
  {
    title: 'Online Certification Exams (Certiport Partnership)',
    description: 'We are an official Certiport partner, providing students with the ability to sit for online certification exams from the comfort of their homes. Our partnership with Certiport ensures that students can earn industry-recognized certifications, making them stand out in their career fields, including IT, digital literacy, and business productivity.',
  },
  {
    title: 'Self-paced Learning through Video Tutorials',
    description: 'Our extensive collection of video tutorials allows students to learn at their own pace. These tutorials cover a wide range of topics, enabling students to dive deep into subjects of interest and revisit content at any time, making learning flexible and pressure-free.',
  },
  {
    title: 'Lifetime Access to Recorded Online Classes',
    description: "Every class held online is recorded and made available to students for lifetime access. This feature allows students to revisit the material whenever needed, whether it's to reinforce concepts, prepare for exams, or simply refresh their knowledge months or even years after completing a course.",
  },
  {
    title: 'Interactive Live Online Classes',
    description: "Our live classes are interactive, with instructors who engage students in discussions, problem-solving, and hands-on learning. This creates an environment similar to a physical classroom, ensuring that students don't miss out on the personal interaction and collaboration typically associated with in -person learning.",
  },
  {
    title: 'Certified and Experienced Instructors',
    description: "All our instructors are certified professionals with years of industry experience, ensuring students receive high-quality education and guidance. Their expertise helps students prepare for the challenges of the professional world while ensuring they are fully equipped to pass their certification exams.",
  },
  {
    title: 'Personalized Learning Support',
    description: "We recognize that every student has unique learning needs. That’s why we provide personalized support through one-on-one sessions, extra study resources, and dedicated mentors. Whether it’s technical assistance, guidance in studies, or exam preparation, our team ensures every student feels supported throughout their learning journey.",
  },
  {
    title: 'Practical Skills Development',
    description: "Our courses are designed to go beyond theoretical knowledge. We emphasize practical, hands-on skills development, ensuring that students can apply what they learn directly in the workplace. This helps them become job-ready and confident in their capabilities.",
  },
  {
    title: 'Affordable and Accessible Education',
    description: "We believe quality education should be accessible to all. Our pricing plans are designed to be affordable while offering value, allowing students from different backgrounds to benefit from our courses and certifications without financial burden.",
  }
]


const Landing = () => {
  const [courses, setCourses] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    handleGetHomeCourses()
  }, [])

  const handleGetHomeCourses = async () => {
    setIsLoading(true)
    try {
      const response = await getHomeCourses()
      const sortedResponse = response.sort((a, b) => a?.orderNumber - b?.orderNumber)
      setCourses(sortedResponse)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  return (
    <div className='landing-page' id='landinghome'>
      <header>
        <div className='header-conteiner'>
          <div className='logo'>
            <a href='/'>
              <img src={logo} alt='logo' />
            </a>
          </div>
          <div className='btns'>
            <Button
              type='link'
              className='signup'
              onClick={() => {
                window.location.href = '/register';
              }}
            >
              Student Registration
            </Button>
            <Button
              type='primary'
              className='signin'
              onClick={
                () => {
                  window.location.href = "/login";
                }
              }
            >
              Sign In
            </Button>
          </div>
        </div>
      </header>

      <div className='body'>
        <section className='hero'>
          <div className='content'>
            <h2>
              Welcome to
            </h2>
            <h1>
              Digital Academy
            </h1>
            <p>
              Hybrid Training Center for Distance Learning
            </p>

            <div className='buttons'>
              <Button
                type='primary'
                className='signin'
                onClick={
                  () => {
                    window.location.href = "/dashboard/cources";
                    history.push('/dashboard/cources');
                  }
                }
              >
                Explore Courses
              </Button>

              <Button
                type='link'
                className='signup'
                onClick={() => {
                  window.location.href = '/register';
                }}
              >
                Register Now
              </Button>
            </div>
          </div>

          <div className='image'>
            <img src={heroImage} alt='hero' />
          </div>
        </section>

        <section className='about'>
          <h1>
            Hybrid Training Center
          </h1>

          <p>
            We are a team of highly qualified and well experienced industry experts dedicated to providing hassle free, low cost and top quality online education to students from all corners in the island. We have experience in working both in Sri Lanka and overseas and we use state-of-the-art technologies mainly from industry leading software services giant Microsoft. We bring to you many paid software solutions and services for free.
          </p>

          <p>
            We provide your children an online education enriched with attractive audio visual content delivered by well experienced educators making education fun and exciting. We make the full use of latest technologies to educate, coach guide and mentor our exiting and our classes are much more than just a video call
          </p>
        </section>

        <section className='carousel-section'>
          <Carousel autoplay>
            <div>
              <img src={image1} alt='carousel 1' />
            </div>
            <div>
              <img src={image2} alt='carousel2' />
            </div>
            <div>
              <img src={image3} alt='carousel3' />
            </div>
            <div>
              <img src={image4} alt='carousel4' />
            </div>
          </Carousel>
        </section>

        <section className='courses '>
          <h1>
            Our Courses
          </h1>

          <div className='cources-body'>
            <Spin
              tip="Loading..."
              spinning={isLoading}
            >
              {courses ? <List
                grid={{
                  gutter: 30,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 2,
                  xl: 3,
                  xxl: 4,
                }}
                dataSource={courses}
                renderItem={(item) => (
                  <List.Item>
                    <div className='course-card'>
                      <div className='card-image-container'>
                        <img
                          src={
                            item?.coverImage
                              ? item?.coverImage
                              : DEFAULT_COURSE_COVER_IMAGE
                          } alt=""
                        />
                      </div>
                      <div className='card-body'>
                        <div className='card-description'>
                          <div className='price'>
                            {
                              item?.price === 0
                                ?
                                <p className='free-text'>
                                  Free
                                </p>
                                :
                                <p>
                                  {item?.price} LKR
                                </p>
                            }
                          </div>

                          <div className='author'>
                            <img
                              src={
                                item?.createdBy?.profileImage
                                  ? item?.createdBy?.profileImage
                                  : DEFAULT_USER_PROFILE_IMAGE
                              } alt=""
                            />
                            <h3>
                              {item?.createdBy?.name}
                            </h3>
                          </div>
                        </div>

                        <h3 className='title'>
                          {item?.name}
                        </h3>
                      </div>

                      <div className='card-footer'>
                        <div className='button-continer'>
                          <Link
                            className='view-btn'
                            to={`/dashboard/cources/${item?.id}`}>
                            <Button
                              type='link'
                            >
                              View
                            </Button>
                          </Link>

                          <>
                            {
                              item?.whatsappGroupLink &&
                              <a href={item?.whatsappGroupLink} target='_blank' rel="noreferrer">
                                <Button type='primary' style={{
                                  backgroundColor: '#25d366',
                                }}>
                                  <WhatsAppOutlined />
                                  Join Whatsapp Group
                                </Button>
                              </a>
                            }
                          </>
                        </div>
                      </div>
                    </div>
                  </List.Item>
                )}
              />

                :
                <div className='no-courcesdiv'>
                </div>
              }
            </Spin>
          </div>
        </section>

        <section className='courses margin_low'>
          <h2>
            Digital Academy: A Gateway to Flexible and Certified Learning
          </h2>

          <p className='para'>
            Digital Academy is a forward-thinking educational institution that began as a hybrid training center, catering to the modern-day student. While offering the convenience of online classes, we ensure that our approach empowers students to balance their education with their personal and professional lives. As a proud partner of Certiport, we offer globally recognized certifications, helping students stay competitive in the job market.
          </p>
          <h3>
            Here are the key advantages of studying with Digital Academy
          </h3>

          <div className='cources-body'>
            <Spin
              tip="Loading..."
              spinning={isLoading}
            >
              <List
                grid={{
                  gutter: 30,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 2,
                  xl: 3,
                  xxl: 3,
                }}
                dataSource={specifications}
                renderItem={(item) => (
                  <List.Item>
                    <div className='list-card'>
                      <h3 >
                        {item?.title}
                      </h3>
                      <div className='description'>
                        <p>
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </Spin>
          </div>

          <p className='para'>
            Digital Academy’s mission is to make learning more accessible, flexible, and effective. With our comprehensive resources, personalized support, and industry-leading certifications, we are committed to helping students achieve their career goals and excel in today’s competitive world.
          </p>
        </section>
      </div >

      <footer>
        <div className='footer-sec'>
          <div className='logo'>
            <a href='/'>
              <img src={logo} alt='logo' />
            </a>
          </div>
          <div className='text'>
            <p>
              Experienced experts offer low-cost, high-quality online education globally, leveraging Microsoft's top technologies. Serving Sri Lanka and beyond, we provide free access to premium software solutions, aiming for hassle-free learning experiences for students island-wide.
            </p>
          </div>
        </div>

        <div className='footer-sec'>
          <h3>
            Contacts
          </h3>
          <div className='text'>
            <p>
              <span><EnvironmentTwoTone /></span>
              634/A/8, Nugedeniya Rd, Thalangama South, Battaramulla. Sri Lanka.
            </p>
            <p>
              <span><PhoneTwoTone /></span>+94-77 4747 920
            </p>
            <p>
              <span><MailTwoTone /></span>
              <a href='/' >
                info@digitalacademy.lk
              </a>
            </p>
          </div>
        </div>

      </footer>

      <section className='copy-right'>
        <p>
          © 2024
          <a
            href='http://tuitionpanthiya.lk/'
            target='_blank'
            rel='noreferrer'
          > Digital Academy.</a> All RIghts Reserved.
        </p>

        <p>
          Designed & Developed by
          <a
            href='https://www.isuruariyarathna.me/'
            target='_blank'
            rel='noreferrer'
          > Isuru Ariyarathna</a>
        </p>
      </section>
    </div >
  )
}

export default Landing