import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { setAllCources } from '../../../../../Reducers/allCourcesReducer';

// Antd
import { Button, Drawer, Form, Input, Select, notification, Spin } from 'antd';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';

import './style.scss';
import { editAssestment, getAllCoursesByRole } from '../../../../../Constants/Api/functions/function';


const { Option } = Select;

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

const EditAssessment = ({
    isOpen,
    setOpen,
    handleGetAssessment,
    assessmentData,
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allCources = useSelector(state => state.allCources.allCources);
    const formRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!allCources) {
            handleGetCources();
        }
    }, [allCources])

    useEffect(() => {
        if (assessmentData && formRef.current) {
            formRef.current.setFieldsValue({
                courseId: assessmentData.courseId,
                name: assessmentData.name,
                description: assessmentData.description,
                assessmentUrl: assessmentData.assessmentUrl,
                endDate: assessmentData.endDate,
                isPublished: assessmentData.isPublished,
            });

        }
    }, [assessmentData, formRef, isOpen])

    const handleGetCources = async () => {
        try {
            const res = await getAllCoursesByRole(userProfile.role, userProfile.id);
            if (res) {
                dispatch(setAllCources(res));
            }
        } catch (error) {
            console.error('EditAssessment', error);
        }
    }


    const onFinish = async (values) => {
        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);
        values.id = assessmentData.id;

        setIsLoading(true);

        try {
            const res = await editAssestment(values)
            if (res && res.status === 200) {
                openNotification('success',
                    'Success',
                    'Assessment updated successfully'
                );
                setOpen(false);
                await handleGetAssessment();
            } else {
                openNotification('error',
                    'Error',
                    res.data.message
                );
            }
        } catch (error) {
            console.error('AddCource', error);
            openNotification('error',
                'Error',
                'Failed to update assessment'
            );
        }

        setIsLoading(false);
    }

    const openNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    return (
        <div>
            {contextHolder}
            <Drawer
                title="Update Assessment"
                width={720}
                onClose={() => setOpen(false)}
                open={isOpen}
            >
                <Spin
                    spinning={isLoading}
                    tip="Please wait..."
                >
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        ref={formRef}
                    >
                        <Form.Item
                            label="Select Course"
                            name="courseId"
                            rules={[{ required: true, message: 'Please select course!' }]}>
                            <Select
                                placeholder="Please select course"
                            >
                                {allCources?.map((course, index) => (
                                    <Option
                                        key={index}
                                        value={course.id}
                                    >
                                        {course.nameEnglish}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Assessment Name"
                            name="name"
                            rules={[{ required: true, message: 'Please enter assessment name!' }]}>
                            <Input
                                placeholder="Please enter assessment name"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{
                                required: true,
                                message: 'Please enter assessment description!'
                            }]}>
                            <ReactQuill
                                modules={modules}
                                theme="snow"
                                placeholder="The description of the assessment"
                                style={{
                                    height: '100%',
                                    zIndex: 10000,
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Assessment URL"
                            name="assessmentUrl"
                            rules={[{ required: true, message: 'Please enter assessment URL!' }]}>
                            <Input
                                placeholder="Please enter assessment URL"
                            />
                        </Form.Item>

                        <Form.Item
                            label="End Date"
                            name="endDate"
                            rules={[{
                                required: true,
                                message: 'Please select end date!'
                            }]}>
                            <Input
                                type="date"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Is Published"
                            name="isPublished"
                            rules={[{
                                required: true,
                                message: 'Please select is published!'
                            }]}>
                            <Select
                                placeholder="Please select is published"
                            >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>

                        <Button
                            type="primary" htmlType="submit">
                            Update Assessment
                        </Button>

                    </Form>
                </Spin>
            </Drawer>
        </div>
    )
}

export default EditAssessment