import { createSlice } from "@reduxjs/toolkit";

const allUsersReducer = createSlice({
    name: "allUsers",
    initialState: {
        allUsers: [],
        isLoading: false,
        error: null
    },
    reducers: {
        setAllUsers: (state, action) => {
            state.allUsers = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setAllUsers, setIsLoading, setError } = allUsersReducer.actions

export default allUsersReducer.reducer;